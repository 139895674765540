import React from 'react';
import { connect } from 'react-redux';

import Permissions from '../../utils/constants/Permissions';

import { isAnyOfFirstInSecond } from '../../utils/helpers/general';

const {
  CAN_CREATE_PAYMENTS,
  CAN_CREATE_PROMISSORY_NOTE,
  CAN_EDIT_LOAN_APPLICATION,
  CAN_CREATE_CONTRACT,
  CAN_EDIT_RATING,
  CAN_APPLY_DISCOUNT,
  CAN_READ_LOAN_APPLICATION_NOTES,
  CAN_CREATE_LOAN_APPLICATION_NOTE,
  CAN_ARCHIVE_LOAN_APPLICATION,
  CAN_CREATE_PERMISSIONS,
  CAN_READ_PERMISSIONS,
  CAN_GRANT_PERMISSIONS,
  CAN_REVOKE_PERMISSIONS,
  CAN_CREATE_LOANS,
  CAN_UPDATE_BANK_ACCOUNT_BALANCE,
} = Permissions;

/**
 * Renders children component if user permissions match any of allowed permissions.
 * @param {Array} allowedPermissions Allowed permissions array.
 */
const withAuthorize = allowedPermissions => {
  class Authorize extends React.Component {
    render() {
      const { userPermissions, children } = this.props;
      if (isAnyOfFirstInSecond(allowedPermissions, userPermissions))
        return children;
      return false;
    }
  }
  const mapStateToProps = state => {
    const { permissions } = state.user;
    return {
      userPermissions: permissions,
    };
  };
  return connect(mapStateToProps)(Authorize);
};

const withAuthorizeRole = allowedRoles => {
  // eslint-disable-next-line react/no-multi-comp
  class Authorize extends React.Component {
    render() {
      const { role, children } = this.props;
      if (isAnyOfFirstInSecond(allowedRoles, [role])) return children;
      return false;
    }
  }
  const mapStateToProps = state => {
    const { role } = state.user;
    return {
      role,
    };
  };
  return connect(mapStateToProps)(Authorize);
};

const userHasPermission = permission => {
  class Authorize extends React.Component {
    render() {
      const { userPermissions, children } = this.props;
      if (isAnyOfFirstInSecond(permission, userPermissions)) return children;
      return false;
    }
  }
  const mapStateToProps = state => {
    const { permissions } = state.user;
    return {
      userPermissions: permissions,
    };
  };
  return connect(mapStateToProps)(Authorize);
};

export const Roles = {
  ADVISOR: 'advisor',
  OPERATIONS: 'operations',
};

export const CanAccessOperationsActions = withAuthorizeRole([Roles.OPERATIONS]);
export const CanAccessDistributorActions = withAuthorizeRole([
  Roles.OPERATIONS,
  Roles.ADVISOR,
]);

export const CanCreatePayment = withAuthorize([CAN_CREATE_PAYMENTS.value]);
export const CanCreatePromissoryNote = withAuthorize([
  CAN_CREATE_PROMISSORY_NOTE.value,
]);
export const CanCreateContract = withAuthorize([CAN_CREATE_CONTRACT.value]);
export const CanEditLoanApplication = withAuthorize([
  CAN_EDIT_LOAN_APPLICATION.value,
]);
export const CanEditRating = withAuthorize([CAN_EDIT_RATING.value]);
export const CanApplyDiscount = withAuthorize([CAN_APPLY_DISCOUNT.value]);
export const CanReadLoanApplicationNotes = withAuthorize([
  CAN_READ_LOAN_APPLICATION_NOTES.value,
]);
export const CanCreateLoanApplicationNote = withAuthorize([
  CAN_CREATE_LOAN_APPLICATION_NOTE.value,
]);
export const CanArchiveApplicationNote = withAuthorize([
  CAN_ARCHIVE_LOAN_APPLICATION.value,
]);
export const CanCreatePermissions = withAuthorize([
  CAN_CREATE_PERMISSIONS.value,
]);
export const CanReadPermissions = withAuthorize([CAN_READ_PERMISSIONS.value]);
export const CanGrantOrRevokePermissions = withAuthorize([
  CAN_GRANT_PERMISSIONS.value,
  CAN_REVOKE_PERMISSIONS.value,
]);
export const CanCreateContractOrPromissoryNote = withAuthorize([
  CAN_CREATE_CONTRACT.value,
  CAN_CREATE_PROMISSORY_NOTE.value,
]);
export const CanCreateLoan = withAuthorize([CAN_CREATE_LOANS.value]);
export const CanUpdateBankAccountBalance = withAuthorize([
  CAN_UPDATE_BANK_ACCOUNT_BALANCE.value,
]);
// Below:
// Dom's cowboy permissions because he can't work out how the above ones work.
export const CanCreateUser = userHasPermission(['dino:api:user:create', '*']);
export const CanEditUser = userHasPermission(['dino:api:user:update', '*']);
export const CanCreateDistributorAdmin = userHasPermission([
  'dino:api:distributoradmin:create',
  '*',
]);
export const CanCreateDistributorPlatform = userHasPermission([
  'dino:api:distributorplatform:create',
  '*',
]);
export const CanCreateAdmin = userHasPermission(['dino:api:ops:create', '*']);
export const CanViewAdmin = userHasPermission(['dino:api:ops:read', '*']);
export const CanCreateWallet = userHasPermission([
  'dino:api:profile:wallet:create',
  '*',
]);
export const CanCreatePayin = userHasPermission([
  'dino:api:movement:deposit:create',
  '*',
]);
export const CanCreatePayout = userHasPermission([
  'dino:api:movement:withdrawal:create',
  '*',
]);
export const CanCreateTransfer = userHasPermission([
  'dino:api:movement:transfer:run',
  '*',
]);
export const CanCreateBankAccount = userHasPermission([
  'dino:api:profile:beneficiarybank:create',
  '*',
]);
export const CanCreateKyc = userHasPermission(['dino:api:kyc:create', '*']);
export const CanCreateMandate = userHasPermission([
  'dino:api:mandate:create',
  '*',
]);
