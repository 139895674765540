import React from 'react';
import { connect } from 'react-redux';
import '../../css/spinner/spinner.css';
import { Flex, Box } from 'components/grid';
// import WalletLogo from 'icons/WalletLogo';
// import { rem } from 'polished';
import FountainLoading from './FountainLoader';

const Spinner = ({ show, visible = false }) => {
  const showClass = show || visible ? 'show' : '';
  return (
    <div className={`spinner-container ${showClass}`}>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        css="height: 100%"
      >
        {/* <Box mb={rem(10)}>
          <WalletLogo color="white" size="100" />
        </Box> */}
        <Box>
          <FountainLoading />
        </Box>
      </Flex>
    </div>
  );
};

const mapStateToProps = state => {
  const show = state.ui.showSpinner;
  return {
    show,
  };
};

export default connect(mapStateToProps)(Spinner);
