import { SET_PROFILES } from '../actions/profilesTypes';
const profilesInitialState = {
  profiles: []
};
export default (state = profilesInitialState, action) => {
  switch (action.type) {
    case SET_PROFILES:
      return {
        profiles: [...action.profiles]
      };
    default:
      return state;
  }
};
