import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { setAuthenticatedProfile } from 'actions/userActionCreators';
import { compose } from 'redux';
// import getAdvisorOwnDetails from 'services/api/advisor/getAdvisorOwnDetails';
// import getOpsOwnDetails from 'services/api/ops/getOpsOwnDetails';

import withAppComponent from 'components/HOC/AppComponent';
import { withPaged } from 'components/HOC';

// import { isEqual } from 'lodash';
// import { formatCurrency } from 'utils/extensions/NumberExtensions';
// import { TableLayout } from 'components/Layout';
// import getEmiWalletList from 'services/api/wallet/getEmiWalletList';
// import { CanAccessOperationsActions } from 'components/HOC/Authorize';
// import Jumbotron1 from '../elements/Jumbotron1';

import { logout } from '../actions/authActionCreators';

// eslint-disable-next-line react/prefer-stateless-function
class Dormant extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.fields = ['ID', 'Balance'];
  //   this.state = {
  //     // paging: {
  //     // hasNextPage: false,
  //     // },
  //     wallets: [],
  //   };
  // }

  // async componentDidMount() {
  //   if (localStorage.getItem('isLoggedIn') === '1') {
  //     if (localStorage.getItem('hasValidatedOtp') === '1') {
  //       const { role, setAuthProfile } = this.props;
  //       switch (role) {
  //         case 'advisor': {
  //           const resAd = await getAdvisorOwnDetails();
  //           setAuthProfile(resAd.data.data);
  //           break;
  //         }
  //         case 'operations': {
  //           console.log("about to send getOpsOwnDetails 2");
  //           try {
  //             const resAd = await getOpsOwnDetails();
  //             console.log("DOMDEBUG received data from /self endpoint");
  //             console.log(resAd);
  //             setAuthProfile(resAd.data.data);
  //           } catch (error) {
  //             console.log(error.response);
  //             const errorMessage = error.response.data.meta.errorMessage;
  //             if (errorMessage.toLowerCase().includes("dormant")) {
  //               console.log("we are dormant");
  //               // this.props.history.replace('/dormant');
  //               return;
  //             }
  //           }

  //           break;
  //         }
  //         default:
  //           break;
  //       }
  //       // this.props.history.replace('/dashboard');
  //     } else {
  //       // this.props.history.replace('/otp');
  //     }
  //   }
  //   // this.getEmiWallets();
  // }

  // componentDidUpdate(prevProps) {
  // const { page, filter } = this.props;
  // if (prevProps.page !== page || !isEqual(prevProps.filter, filter))
  //   this.getEmiWallets();
  // }

  // getEmiWallets = async () => {
  //   const { role } = this.props;
  //   if (role === 'operations') {
  //     this.props.handleAsyncRequest(async () => {
  //       const { page, filter } = this.props;
  //
  //       const response = await getEmiWalletList({
  //         page,
  //         ...filter,
  //         sortBy: 'cvl',
  //         includeToBeProcessed: true,
  //       });
  //       // const { values: wallets, ...paging } = response.data.data;
  //       const { values: wallets } = response.data.data;
  //
  //       // this.setState({ paging, wallets });
  //       this.setState({ wallets });
  //     });
  //   }
  // };

  render() {
    // const headerFields = this.fields.map((f, i) => (
    //   <th key={`product-header-field-${i}`}>{f}</th>
    // ));

    // const walletRows = this.state.wallets.map((u, i) => {
    //   const { id, balance, currencyAlpha3Code } = u;
    //   return (
    //     <tr key={`wallet-row-${i}`}>
    //       <td>
    //         <Link to={`/user-wallets/edit/${id}/details`}>{id}</Link>
    //       </td>
    //       <td>{formatCurrency(balance, { currencyAlpha3Code })}</td>
    //     </tr>
    //   );
    // });
    // {/*<div>*/}
    //     {/*<Jumbotron1 />*/}
    //     {/*<div className="row">
    //       <div className="col">
    //         Dormant
    //       </div>
    //     </div>
    //   </div>*/}
    //   {/*<Widget title="Your user is dormant"></Widget>*/}
    return (
      <center style={{ paddingTop: '50px' }}>
        <h1>Dormant</h1>
        <p>
          Your account has been deactivated because you have not logged in for
          30 days. <br />
          Please contact an admin to reactivate it.
        </p>
        <button className="btn btn-secondary" onClick={this.props.logout}>
          Log out
        </button>
      </center>
    );
  }
}

const mapStateToProps = state => ({
  role: state.user.role,
});

const actions = {
  setAuthProfile: setAuthenticatedProfile,
};

export default compose(
  withPaged,
  withRouter,
  withAppComponent,
  connect(mapStateToProps, { actions, logout })
)(Dormant);
