import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import PagingContainer from '../Layout/PagingContainer';

class PagesContainer extends React.Component {
  renderPagingContainer = pageNumberPosition => {
    const { componentRoutePath, nameForScrolling, hasNextPage } = this.props;

    if (!componentRoutePath) return false;
    return (
      <PagingContainer
        path={componentRoutePath}
        scrollToElement={nameForScrolling}
        pageNumberPosition={pageNumberPosition}
        hasNextPage={hasNextPage}
      />
    );
  };

  render() {
    const { nameForScrolling } = this.props;

    return (
      <div>
        {this.renderPagingContainer('bottom')}
        <Element name={nameForScrolling}>{this.props.children}</Element>
        {this.renderPagingContainer('top')}
      </div>
    );
  }
}

PagesContainer.propTypes = {
  nameForScrolling: PropTypes.string.isRequired,
  componentRoutePath: PropTypes.string.isRequired,
};

PagesContainer.defaultProps = {
  hasNextPage: false,
};

export default PagesContainer;
