import { SET_LOGGED_USER_PROFILE } from '../actions/userTypes';

export const userInitialState = {
  userId: null,
  email: null,
  name: null,
  permissions: [],
  profileId: null,
  partner: null,
  referenceCode: null,

  role: '',
  advisorId: '',
  firstName: '',
  lastName: '',
  identityProviderId: '',
  language: '',
  createdAt: '',
  updatedAt: '',
};

export default (state = userInitialState, action) => {
  switch (action.type) {
    case SET_LOGGED_USER_PROFILE: {
      return {
        ...state,
        ...action.payload,
        name: action.payload.firstName,
      };
    }
    case 'SET_ROLE':
      return {
        ...state,
        role: action.payload.role,
      };

    default:
      return state;
  }
};
