import React from 'react';
import { connect } from 'react-redux';
import { showSpinner } from '../../actions/general';
// import Loadable from 'react-loadable';
// import Spinner from '../UI/Spinner';

// export default importComponent => Loadable({
//   loader: importComponent,
//   loading: SpinnerBlock,
// });
export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { showSpinner } = this.props;
      showSpinner(true);
      const { default: component } = await importComponent();
      showSpinner(false);
      this.setState({
        component,
      });
    }

    render() {
      const { component: C } = this.state;

      return C ? <C {...this.props} /> : null;
    }
  }

  return connect(null, { showSpinner })(AsyncComponent);
}
