import React from 'react';
import { connect } from 'react-redux';
import SidebarHeading from './SidebarHeading';
import Menu from './Menu';
import '../css/elements/left-sidebar-1.css';
import '../css/elements/collapsed-left-sidebar-1.css';

const LeftSidebar = ({ navigation }) => (
  <div>
    <div className="left-sidebar-placeholder" />
    <div className="left-sidebar left-sidebar-1">
      <div className="wrapper">
        <div className="content">
          <SidebarHeading />
          {navigation.map((menu, i) => (
            <div key={i} className="section">
              <div className="section-title">{menu.title}</div>
              <ul className="list-unstyled">
                {menu.items.map((item, mi) => (
                  <Menu key={mi} items={item} />
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
const mapStateToProps = state => ({
  navigation: state.navigation,
});
export default connect(mapStateToProps)(LeftSidebar);
