import React from 'react';

import * as Provinces from '../constants/Provinces';
import * as CivilStatus from '../constants/CivilStatus';
import * as LoanReason from '../constants/LoanReason';
import * as Partners from '../constants/Partners';

import LoanApplicationTypes from '../constants/RequestType';

import {
  APPROVED,
  CANCELED,
  SIGNED,
  NO_CONTACT,
  UNABLE_TO_REACH,
  TRY_RECALL,
  DOES_NOT_WANT,
  DOES_NOT_HAVE,
  PENDING,
  REJECTED,
  RECEIVE_DOC,
  VALIDATE_DOC,
  DISBURSED,
  PENDING_VALIDATION,
} from '../constants/RequestStatus';

export const requestStatusText = {
  [APPROVED]: 'Aprobado',
  [CANCELED]: 'Cancelado',
  [SIGNED]: 'Firmado',
  [NO_CONTACT]: 'Sin contactar',
  [UNABLE_TO_REACH]: 'No pude comunicarme',
  [TRY_RECALL]: 'Volver a llamar',
  [DOES_NOT_WANT]: 'No quiere',
  [DOES_NOT_HAVE]: 'No tiene',
  [PENDING]: 'Pendiente',
  [REJECTED]: 'Rechazado',
  [RECEIVE_DOC]: 'Recibir documentación',
  [VALIDATE_DOC]: 'Validar documentación',
  [DISBURSED]: 'Desembolsado',
  [PENDING_VALIDATION]: 'En revisión, pendiente de validación',
};
export const civilStatusOptions = Object.keys(CivilStatus).map(cs => ({
  text: CivilStatus[cs],
  value: CivilStatus[cs],
}));
export const loanReasonOptions = Object.keys(LoanReason).map(lr => ({
  text: LoanReason[lr],
  value: LoanReason[lr],
}));
export const provincesOptions = Object.keys(Provinces).map(p => ({
  text: Provinces[p],
  value: Provinces[p],
}));

export const partnersOptions = Object.keys(Partners).map(p => ({
  text: Partners[p],
  value: Partners[p],
}));

export const attributesByStatus = {
  late: {
    variant: 'warning',
    text: 'Retrasado',
  },
  legal: {
    variant: 'danger',
    text: 'Legal',
  },
  ok: {
    variant: 'success',
    text: 'Activo',
  },
};
export const getClassFromRating = rating => {
  if (rating >= 0.75 && rating <= 1) return 'AAA';
  if (rating >= 0.7 && rating <= 0.75) return 'AA';
  if (rating >= 0.65 && rating <= 0.7) return 'A';
  if (rating >= 0.6 && rating <= 0.65) return 'BBB';
  if (rating >= 0.55 && rating <= 0.6) return 'BB';
  if (rating >= 0.5 && rating <= 0.55) return 'B';
  if (rating >= 0.45 && rating <= 0.5) return 'C';
  if (rating >= 0.4 && rating <= 0.45) return 'D';
  if (rating >= 0.35 && rating <= 0.4) return 'E';
  if (rating >= 0.3 && rating <= 0.35) return 'F';
  return 'HR';
};

export const getRating = (
  score,
  income,
  dueRequest,
  dueOther,
  adjustedIncome,
  loanAmount,
  propertyValue
) => {
  const due = dueRequest + dueOther;
  const ponderations = {
    score: 0.8,
    risk: 0.2,
  };

  const availableRent = (income - due) / income;
  const paymentProbability =
    (score / 1000) * ponderations.score + availableRent * ponderations.risk;

  const recoveryProbability = 1 - loanAmount / propertyValue;

  return (
    paymentProbability * ponderations.score +
    recoveryProbability * ponderations.risk
  );
};

export const getRatingText = rating => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(rating) || rating === -Infinity || rating === Infinity) return '-';
  return rating.toFixed(2);
};

export const getWarningsAndErrorsFromRatingFormValues = (
  loanApplicationType,
  ratingFormValues,
  requestedAmount
) => {
  let errors = [];
  let warnings = [];

  const {
    dueRequest,
    dueOther,
    loanAmount,
    income,
    propertyValue,
    score,
  } = ratingFormValues;

  const requestPercentage = (100 * requestedAmount) / propertyValue;
  const quotaPercentage = (100 * (dueRequest + dueOther)) / income;

  const setWarningError = (message, type) => {
    switch (type) {
      case 'warning':
        warnings = warnings.concat(
          <li key={`warning-${message}`} className="warning">
            {message}
          </li>
        );
        break;
      case 'error':
        errors = errors.concat(
          <li key={`error-${message}`} className="error">
            {message}
          </li>
        );

        break;
      default:
        break;
    }
  };

  const dataCreditScoreSolutionAlert = () => {
    if (requestPercentage >= 80 && parseInt(score, 10) < 850) {
      setWarningError(
        'Para el financiamiento del 80%, el score debe de estar por encima de 850',
        'error'
      );
    } else if (requestPercentage >= 70 && parseInt(score, 10) < 750) {
      setWarningError(
        'Para el financiamiento del 70%, el score debe de estar por encima de 750',
        'error'
      );
    } else if (requestPercentage >= 60 && parseInt(score, 10) < 650) {
      setWarningError(
        'Para el financiamiento del 60%, el score debe de estar por encima de 650',
        'error'
      );
    } else if (requestPercentage >= 50 && parseInt(score, 10) < 550) {
      setWarningError(
        'Para el financiamiento del 50%, el score debe de estar por encima de 550',
        'error'
      );
    } else if (requestPercentage >= 40 && parseInt(score, 10) < 450) {
      setWarningError(
        'Para el financiamiento del 40%, el score debe de estar por encima de 450',
        'error'
      );
    } else if (requestPercentage >= 30 && parseInt(score, 10) < 350) {
      setWarningError(
        'Para el financiamiento del 30%, el score debe de estar por encima de 350',
        'error'
      );
    } else if (requestPercentage >= 20 && parseInt(score, 10) < 250) {
      setWarningError(
        'Para el financiamiento del 20%, el score debe de estar por encima de 250',
        'error'
      );
    } else if (requestPercentage >= 10 && parseInt(score, 10) < 150) {
      setWarningError(
        'Para el financiamiento del 10%, el score debe de estar por encima de 150',
        'error'
      );
    }
  };

  const dataCreditScoreHomeAlert = () => {
    if (requestPercentage === 100 && parseInt(score, 10) < 750) {
      setWarningError(
        'Para el financiamiento del 100%, el score debe de estar por encima de 750',
        'error'
      );
    } else if (requestPercentage >= 80 && parseInt(score, 10) < 650) {
      setWarningError(
        'Para el financiamiento del 80%, el score debe de estar por encima de 650',
        'error'
      );
    } else if (requestPercentage >= 60 && parseInt(score, 10) < 550) {
      setWarningError(
        'Para el financiamiento del 60%, el score debe de estar por encima de 550',
        'error'
      );
    }
  };

  const dataCreditAmountSolutionAlert = () => {
    const isLoanAmountGreaterThan30PropertyValue =
      loanAmount > propertyValue * 0.3;

    const isDueRequestGreaterThan50Income = dueRequest > income * 0.5;

    const isDuesGreaterThan100Income = dueRequest + dueOther > income;
    const isDuesGreaterThan80Income = dueRequest + dueOther > income * 0.8;

    // Loan amount and property value.
    if (loanApplicationType === LoanApplicationTypes.HOME.value) {
      const isLoanAmountGreaterThan80PropertyValue =
        loanAmount > propertyValue * 0.8;
      if (isLoanAmountGreaterThan80PropertyValue)
        setWarningError(
          'El monto solicitado es mayor a 80% del valor de la vivienda',
          'warning'
        );
    } else if (isLoanAmountGreaterThan30PropertyValue)
      setWarningError(
        'El monto solicitado es mayor a 30% del valor de la vivienda',
        'warning'
      );

    if (isDueRequestGreaterThan50Income)
      setWarningError(
        'La cuota es mayor al 50% de los ingresos estimados',
        'error'
      );

    if (isDuesGreaterThan100Income)
      setWarningError(
        'El total de cuotas es mayor al 100% de los ingresos estimados',
        'error'
      );
    else if (isDuesGreaterThan80Income)
      setWarningError(
        'El total de cuotas es mayor al 80% de los ingresos estimados',
        'warning'
      );
  };

  // Data credit score warnings and errors
  if (loanApplicationType === LoanApplicationTypes.SOLUTION.value) {
    dataCreditScoreSolutionAlert();
  } else if (loanApplicationType === LoanApplicationTypes.HOME.value) {
    dataCreditScoreHomeAlert();
  }

  if (quotaPercentage >= 30 && quotaPercentage < 50) {
    setWarningError(
      'La sumatoria de las cuotas es mayor o igual al 30% de los ingresos',
      'warning'
    );
  } else if (quotaPercentage >= 50) {
    setWarningError(
      'La sumatoria de las cuotas es mayor o igual al 50% de los ingresos',
      'error'
    );
  }

  dataCreditAmountSolutionAlert();

  return { errors, warnings };
};
