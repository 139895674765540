import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { config } from './config';
import { colors, backgroundColors } from './colors';
import { navigation } from './navigation';
import uiReducer from './ui';
import loanInfoModalReducer from './loanInfoModalReducer';
import notesModalReducer from './notesModalReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import profiles from './profilesReducer';
import profile from './profileReducer';
import permission from './permissionReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    config,
    colors,
    backgroundColors,
    navigation,
    ui: uiReducer,
    form: formReducer,
    loanInfoModal: loanInfoModalReducer,
    notesModal: notesModalReducer,
    auth: authReducer,
    user: userReducer,
    profiles,
    profile,
    permission,
  });
