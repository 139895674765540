import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { startCase } from 'lodash';
import '../css/elements/jumbotron-1.css';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

// import { QuotesGenerator } from '../components/General';

const Jumbotron1 = ({ location, name, t }) => {
  const { pathname } = location;
  const parts =
    pathname && pathname.split('/').filter(str => str.trim().length > 0);
  // let controller =
  //   parts && parts[0]
  //     ? startCase(parts[0]).replace(/Ui /g, 'UI ')
  //     : 'Bienvenido/a';
  const view =
    parts && parts[1] ? startCase(parts[1]) : t('dashboard.welcome', { name });
  // let show = pathname === '/' ? false : true;
  // if (!show ||config.layout === 'top-navigation-1' || config.layout === 'empty-view-1' || parts[0] === 'layouts' || parts[0] === 'dashboards') {
  //   return <div className="hidden" />
  // }
  return (
    <div className="jumbotron jumbotron-1" style={{ paddingLeft: '0' }}>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col">
            <h4
              id="welcomeTitle"
              style={{ opacity: 0, transition: 'opacity 500ms' }}
            >
              {view}
            </h4>
            {/* <h5>{controller}</h5> */}
          </div>
          <div className="col hidden-md-down">
            {/* <QuotesGenerator /> */}

            {/* <div className="p-t-10">
              <button className="btn btn-info btn-rounded btn-icon m-r-10">
                <i className="sli-star" />
                <span>Follow</span>
              </button>
              <button className="btn btn-success btn-rounded btn-icon">
                <i className="sli-cursor" />
                <span>Message</span>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { firstName, lastName } = state.user;
  if (
    firstName != null &&
    lastName != null &&
    document.getElementById('welcomeTitle') != null
  ) {
    document.getElementById('welcomeTitle').style.opacity = 1;
  }
  return {
    config: state.config,
    name: `${firstName} ${lastName}`,
  };
};

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps)
)(Jumbotron1);
