import AsyncComponent from '../../components/HOC/AsyncComponent';

const AsyncAdmins = AsyncComponent(() => import('./Admins'));
const AsyncUserDetailsPage = AsyncComponent(() => import('./UserDetail/index'));
const AsyncAdminDetailsPage = AsyncComponent(() =>
  import('./AdminDetail/index')
);
const AsyncDistributorPlatformDetailsPage = AsyncComponent(() =>
  import('./DistributorPlatformDetail/index')
);
const AsyncWalletDetailsPage = AsyncComponent(() =>
  import('./WalletDetail/index')
);
const AsyncBackendBankDetailsPage = AsyncComponent(() =>
  import('./BackendBankDetail/index')
);
const AsyncReconciliationDetailsPage = AsyncComponent(() =>
  import('./ReconciliationDetail/index')
);
const AsyncTransactionsPage = AsyncComponent(() =>
  import('./Transactions/index')
);
const AsyncTransactionsDistributorPlatformPage = AsyncComponent(() =>
  import('./TransactionsDistributorPlatform/index')
);
const AsyncOwnWalletsPage = AsyncComponent(() => import('./OwnWallets/index'));
const AsyncPlatformWalletsPage = AsyncComponent(() =>
  import('./PlatformWallets/index')
);
const AsyncUserWalletsPage = AsyncComponent(() =>
  import('./UserWallets/index')
);
const AsyncTransactionDetailsPage = AsyncComponent(() =>
  import('./TransactionDetails/index')
);
const AsyncMovementsPage = AsyncComponent(() => import('./Movements/index'));

const AsyncPendingMovementsPage = AsyncComponent(() =>
  import('./PendingMovements/index')
);

const AsyncBackendBanksPage = AsyncComponent(() =>
  import('./BackendBanks/index')
);

const AsyncReconciliationsPage = AsyncComponent(() =>
  import('./Reconciliations/index')
);

const AsyncUserScreening = AsyncComponent(() =>
  import('./UserScreening/index')
);

const AsyncUserScreeningDetail = AsyncComponent(() =>
  import('./UserScreeningDetail/index')
);

export {
  AsyncUserDetailsPage,
  AsyncAdminDetailsPage,
  AsyncDistributorPlatformDetailsPage,
  AsyncWalletDetailsPage,
  AsyncBackendBankDetailsPage,
  AsyncReconciliationDetailsPage,
  AsyncTransactionsPage,
  AsyncTransactionsDistributorPlatformPage,
  AsyncOwnWalletsPage,
  AsyncPlatformWalletsPage,
  AsyncUserWalletsPage,
  AsyncTransactionDetailsPage,
  AsyncMovementsPage,
  AsyncPendingMovementsPage,
  AsyncBackendBanksPage,
  AsyncReconciliationsPage,
  AsyncAdmins,
  AsyncUserScreening,
  AsyncUserScreeningDetail,
};
