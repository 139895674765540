import React from 'react';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';

// import { isAnyOfFirstInSecond, isAllOfFirstInSecond } from '../../utils/helpers/general';

export default () =>
  // allowedPermissions
  // mandatoryPermissions = []
  WrappedComponent => {
    const Authorize = props => (
      // const { userPermissions } = props;
      // const isAllowed = isAnyOfFirstInSecond(allowedPermissions, userPermissions);
      // const isAllowedMandatory = isAllOfFirstInSecond(mandatoryPermissions, userPermissions);
      // if (
      //   (mandatoryPermissions.length > 0 && isAllowedMandatory && isAllowed)
      //   || (mandatoryPermissions.length === 0 && isAllowed)
      // ) return <WrappedComponent {...props} />;
      // return <Redirect to="/" />;
      <WrappedComponent {...props} />
    );
    const mapStateToProps = state => {
      const { permissions } = state.user;
      return {
        userPermissions: permissions,
      };
    };
    return connect(mapStateToProps)(Authorize);
  };
