import React from 'react';
import '../css/elements/sidebar-heading-image.css';
const SidebarHeadingImage = () => (
  <div className="sidebar-heading-image">
    {/* <span className="badge badge-sm badge-rounded badge-info">8</span> */}
    {/* <img src="/assets/faces/m7.png" className="rounded-circle" alt="" /> */}
    <img src="/assets/faces/useredited.svg" className="rounded-circle" alt="" />
  </div>
);
export default SidebarHeadingImage;
