import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    // keySeparator: false, // we do not use keys in form messages.welcome
    otherLanguages: ['es'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    // react-i18next options
    react: {
      wait: true,
    },

    // detection: {
    //   // order and from where user language should be detected
    //   order: [
    //     'querystring',
    //     'cookie',
    //     'localStorage',
    //     'navigator',
    //     'htmlTag',
    //     'path',
    //     'subdomain',
    //   ],

    //   // keys or params to lookup language from
    //   lookupQuerystring: 'lng',
    //   lookupCookie: 'i18next',
    //   lookupLocalStorage: 'i18nextLng',
    //   lookupFromPathIndex: 0,
    //   lookupFromSubdomainIndex: 0,

    //   // cache user language on
    //   caches: ['localStorage', 'cookie'],
    //   excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    //   // optional expire and domain for set cookie
    //   cookieMinutes: 10,
    //   cookieDomain: 'myDomain',

    //   // optional htmlTag with lang attribute, the default is:
    //   htmlTag: document.documentElement,
    // },
  });

export default i18n;
