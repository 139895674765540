import requestHandler from '../requestHandler';

const cookieStrictness = process.env.REACT_APP_COOKIE_NO_STRICT
  ? { sameSite: 'None' }
  : {};

const setCookie = token =>
  requestHandler.put(`/api/v1/auth/cookie`, cookieStrictness, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const deleteCookie = () => requestHandler.delete(`/api/v1/auth/cookie`, {});

export { setCookie, deleteCookie };
