import { SET_PERMISSIONS } from '../actions/permissionsTypes';

const permissionInitialState = {
  permissions: []
};

export default (state = permissionInitialState, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        permissions: action.permissions
      };
    default:
      return state;
  }
};
