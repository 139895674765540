import { INIT_NOTES_MODAL, RESET_NOTES_MODAL } from '../actions/types';
const initialState = {
  notes: [],
  requestId: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_NOTES_MODAL:
      const { notes, requestId } = action;
      return {
        ...state,
        notes,
        requestId
      };
    case RESET_NOTES_MODAL:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
