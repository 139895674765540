export function config(
  state = {
    layout: 'empty-view-1',
    background: 'light',
    navbar: 'secondary',
    topNavigation: 'secondary',
    collapsed: false,
    logo: 'secondary',
    leftSidebar: 'light',
    rightSidebar: false,
    in: false,
  },
  action
) {
  switch (action.type) {
    case 'SET_CONFIG':
      return Object.assign({}, state, {
        ...action.config,
      });
    default:
      return state;
  }
}
