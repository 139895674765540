import {
  loanProjectionUrl,
  loansUrl,
  makeLoanPaymentUrlByLoandId,
  insuranceLoanUrl,
  createLoanContractUrl,
  applyDiscountUrl,
  createPromissoryNoteUrl,
  loanAppUrl,
  updateLoanUrl,
} from '../constants/endpointUrls';

import Service from './Service';

class LoanService extends Service {
  getPaymentProjection(
    loanAmount,
    contractDurationInMonths,
    monthlyInterestRate
  ) {
    let params = {
      loanAmount: 0,
      contractDurationInMonths: 0,
      monthlyInterestRate: 0,
      contractStartDate: Date.now(),
    };
    if (loanAmount && contractDurationInMonths && monthlyInterestRate)
      params = {
        loanAmount,
        contractDurationInMonths,
        monthlyInterestRate,
        contractStartDate: Date.now(),
      };
    return this.getRequest(loanProjectionUrl, params);
  }

  /**
   * Request the loan object
   * @param {any} requestIdOrLoanId Request or loan id to request the loan object
   */
  getLoan(requestIdOrLoanId) {
    return this.getRequest(`${loansUrl}/${requestIdOrLoanId}`);
  }

  makePaymentByLoanId(loanId, amount, time) {
    const params = {
      amount,
      time,
    };
    return this.postRequest(makeLoanPaymentUrlByLoandId(loanId), params);
  }

  createLoanByRequestId(
    loanApplicationId,
    startTime,
    monthlyInterestRate,
    dailyLateChargeRate,
    contractDurationInMonths,
    gracePeriodInDays,
    legalPeriodInDays,
    amount
  ) {
    const params = {
      loanApplicationId,
      startTime,
      monthlyInterestRate,
      dailyLateChargeRate,
      contractDurationInMonths,
      gracePeriodInDays,
      legalPeriodInDays,
      amount,
      type: 'mortgage',
    };
    return this.postRequest(loansUrl, params);
  }

  getLoans(type = undefined, filter, page = 0) {
    if (!type) {
      const params = {
        ...filter,
        page,
      };
      return this.getRequest(loansUrl, params);
    }
    return this.getRequest(insuranceLoanUrl);
  }

  createLoanContract(
    name,
    idCardNumber,
    contractDurationInMonths,
    monthlyFee,
    amount,
    address,
    registrationNumber,
    propertyDescription,
    purpose,
    contractStartTime,
    rate
  ) {
    const params = {
      name,
      idCardNumber,
      contractDurationInMonths,
      monthlyFee,
      amount,
      address,
      registrationNumber,
      propertyDescription,
      purpose,
      contractStartTime: contractStartTime.unix() * 1000,
      registrationTime: Date.now(),
      rate,
    };
    return this.postRequest(createLoanContractUrl, params);
  }

  applyDiscountToDue(dueId, discountedAmount, loanId) {
    const params = {
      discountedAmount,
    };
    return this.postRequest(applyDiscountUrl(loanId, dueId), params);
  }

  createPromissoryNote(
    name,
    idCardNumber,
    contractDurationInMonths,
    monthlyFee,
    amount,
    address,
    registrationNumber,
    propertyDescription,
    purpose,
    contractStartTime,
    firstWitnessName,
    firstWitnessIdCardNumber,
    secondWitnessName,
    secondWitnessIdCardNumber,
    civilStatus
  ) {
    const params = {
      name,
      idCardNumber,
      contractDurationInMonths,
      monthlyFee,
      amount,
      address,
      registrationNumber,
      propertyDescription,
      purpose,
      contractStartTime: contractStartTime.unix() * 1000,
      registrationTime: Date.now(),
      firstWitnessName,
      firstWitnessIdCardNumber,
      secondWitnessName,
      secondWitnessIdCardNumber,
      civilStatus,
    };
    return this.postRequest(createPromissoryNoteUrl, params);
  }

  getLoanApplications(params, page = 0) {
    // eslint-disable-next-line no-param-reassign
    params.page = page;
    return this.getRequest(loanAppUrl, params);
  }

  setOwnerIdToLoan(ownerId, loanId) {
    const params = {
      ownerUserId: ownerId,
    };
    return this.patchRequest(updateLoanUrl(loanId), params);
  }
}

export default new LoanService();
