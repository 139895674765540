import React from 'react';
import { connect } from 'react-redux';
import LeftSidebar from 'elements/LeftSidebar';
import Navbar1 from 'elements/Navbar1';
import { CountriesProvider } from 'context/CountriesContext';
import AlertModal from 'components/UI/AlertModal';
import Spinner from 'components/UI/Spinner';

const AppLayout = ({ config, children }) => {
  const {
    //   layout,
    background,
    navbar,
    logo,
    leftSidebar,
    topNavigation,
    collapsed,
  } = config;

  return (
    <CountriesProvider>
      <div
        data-layout={'default-sidebar-1'}
        data-background={background}
        data-navbar={navbar}
        data-logo={logo}
        data-left-sidebar={leftSidebar}
        data-top-navigation={topNavigation}
        data-collapsed={collapsed}
      >
        <Navbar1 />
        <AlertModal />

        <div className="container-fluid">
          <div className="row">
            <LeftSidebar />
            <div className="col main">
              <React.Suspense fallback={<Spinner visible />}>
                {children}
              </React.Suspense>
            </div>
          </div>
        </div>
      </div>
    </CountriesProvider>
  );
};

const mapStateToProps = state => ({
  config: state.config,
});

export default connect(mapStateToProps)(AppLayout);
