import React from 'react';
import { connect } from 'react-redux';
import { showSpinner } from 'actions/general';
import { compose } from 'redux';
import * as authActionCreators from 'actions/authActionCreators';
import { toast } from 'react-toastify';

const actions = {
  spinner: showSpinner,
  logout: authActionCreators.logout,
};

export default function withAppComponent(WrappedComponent) {
  const AppComponent = ({ spinner, logout, ...rest }) => {
    const handleAsyncRequest = async (asyncMethod, config = {}) => {
      const viewSpinner = Object.prototype.hasOwnProperty.call(
        config,
        'spinner'
      )
        ? config.spinner
        : true;

      try {
        if (viewSpinner) spinner(true);
        if (config.try) await config.try();
        await asyncMethod();
        if (config.successNotification)
          toast.success('Changes made succesfully');
      } catch (e) {
        window.console.error(e);
        if (e.response && e.response.status === 403) {
          logout();
          return;
        }
        if (config.catch && typeof (config.catch === 'function'))
          await config.catch();
        else toast.error('Something went wrong');
      } finally {
        if (config.finally) await config.finally();

        if (viewSpinner) spinner(false);
      }
    };

    return (
      <WrappedComponent {...rest} handleAsyncRequest={handleAsyncRequest} />
    );
  };

  return compose(
    connect(
      null,
      actions
    )
  )(AppComponent);
}
