import { SET_PROFILE, CLEAR_PROFILE } from '../actions/profileTypes';

const profileInitialState = {
  email: '',
  userId: '',
  permissions: [],
  name: '',
  partner: '',
  referenceCode: ''
};

export default (state = profileInitialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      const {
        email,
        userId,
        permissions,
        name,
        partner,
        referenceCode
      } = action;
      return {
        ...state,
        name,
        email,
        userId,
        permissions,
        partner,
        referenceCode
      };
    case CLEAR_PROFILE:
      return profileInitialState;

    default:
      return state;
  }
};
