import {
  successRequestUpdateNotification,
  errorRequestUpdateNotification,
  updateAvailableNotification,
} from './notifications';
import LoanService from '../utils/services/LoanService';

import {
  HISTORY_NOTES_MODAL,
  LOAN_INFO_MODAL,
} from '../utils/constants/ModalNames';
import {
  ALERT_MODAL,
  SPINNER,
  SHOW_MODAL,
  // INIT_LOAN_INFO_MODAL,
  INIT_NOTES_MODAL,
  RESET_LOAN_MODAL,
  RESET_NOTES_MODAL,
  UPDATE_FORM_REQUEST_INFO,
} from './types';
import RequestsService from '../utils/services/RequestsService';

import { SIGNED, DISBURSED } from '../utils/constants/RequestStatus';
import { requestStatusText } from '../utils/helpers/requests';
import { PermissionsEnum } from '../utils/constants/Permissions';

// import { getWarningsAndErrorsFromRatingFormValues } from '../utils/helpers/requests';
// import LoanApplicationTypes from '../utils/constants/RequestType';

const { CAN_EDIT_LOAN_APPLICATION } = PermissionsEnum;

const noOp = () => {};

export const checkForUpdates = () => dispatch => {
  dispatch(updateAvailableNotification());
};

export const alertModal = (
  show,
  onConfirm = null,
  onCancel = null,
  alertText = ''
) => ({
  type: ALERT_MODAL,
  show,
  onConfirm,
  onCancel,
  alertText,
});

export const showModal = (modalName, show = true) => ({
  type: SHOW_MODAL,
  modalName,
  show,
});
export const showSpinner = show => ({
  type: SPINNER,
  show,
});
export const viewSpinner = () => ({
  type: SPINNER,
  show: true,
});

export const hideSpinner = () => ({
  type: SPINNER,
  show: false,
});
export const initLoanInfoModal = requestInfo => {
  const fakeValueSoHuskyWillLeaveThisFnAlone = 123;
  return { fakeValueSoHuskyWillLeaveThisFnAlone, requestInfo }; // This is also garbage to stave off Husky
  // const { cedula_identidad } = requestInfo;
  // const formRequestInfo = {
  //   ...requestInfo,
  //   cedula_identidad: cedula_identidad
  //     ? cedula_identidad.replace(/[^0-9]+/g, '')
  //     : cedula_identidad,
  // };
  // return {
  //   type: INIT_LOAN_INFO_MODAL,
  //   requestInfo,
  //   formRequestInfo,
  // };
};
// TODO: This has to be done with user type or permissions!
export const loanModalForInsurance = forInsurance => ({
  type: 'LOAN_MODAL_FOR_INSURANCE',
  forInsurance,
});
export const initLoanInfoModalByRequestId = requestId => dispatch => {
  dispatch(showSpinner(true));
  RequestsService.getRequestById(requestId)
    .then(res => {
      const { data: request } = res.data;

      dispatch(initLoanInfoModal(request));
      dispatch(showModal(LOAN_INFO_MODAL, true));
    })
    .finally(() => dispatch(showSpinner(false)));
};
export const initLoanInfoModalByLoanId = loanId => dispatch => {
  dispatch(showSpinner(true));
  LoanService.getLoan(loanId)(
    response => {
      const { data: loan } = response.data;
      // console.log('loan', loan);
      const { loanApplicationId } = loan;
      RequestsService.getRequestById(loanApplicationId)
        .then(res => {
          const { data: request } = res.data;
          // console.log('request ', request);

          dispatch(initLoanInfoModal(request));
          dispatch(showModal(LOAN_INFO_MODAL, true));
        })
        .finally(() => dispatch(showSpinner(false)));
    },
    () => dispatch(showSpinner(false))
  );
};
const initNotesModal = (requestId, notes = []) => ({
  type: INIT_NOTES_MODAL,
  notes,
  requestId,
});

export const initNotesModalByRequestId = requestId => dispatch => {
  dispatch(showSpinner(true));
  RequestsService.getRequestHistory(requestId)
    .then(res => {
      if (!Object.prototype.hasOwnProperty.call(res.data.data, 'valid'))
        dispatch(initNotesModal(requestId, res.data.data));
      else dispatch(initNotesModal(requestId));
      dispatch(showModal(HISTORY_NOTES_MODAL, true));
    })
    .finally(() => dispatch(showSpinner(false)));
};

export const resetLoanModal = () => ({
  type: RESET_LOAN_MODAL,
});
export const resetNotesModal = () => ({
  type: RESET_NOTES_MODAL,
});

export const closeLoanModal = () => dispatch => {
  dispatch(resetLoanModal());
  dispatch(showModal(LOAN_INFO_MODAL, false));
};

export const updateFormRequestInfo = (name, value) => ({
  type: UPDATE_FORM_REQUEST_INFO,
  name,
  value,
});
export const updateRequest = (
  requestId,
  values,
  onSuccess = noOp,
  onError = noOp
) => dispatch => {
  dispatch(showSpinner(true));
  RequestsService.updateRequest(requestId, values)
    .then(onSuccess)
    .catch(err => {
      const { REACT_APP_ENABLE_LOG_ROCKET: enableLog } = process.env;
      const { LogRocket } = window;
      if (enableLog === 'true' && LogRocket) {
        LogRocket.captureException(err, {
          tags: {
            // additional data to be grouped as "tags"
            subscription: 'pro',
          },
          extra: {
            // additional arbitrary data associated with the event
            action: `Updating request #${requestId}`,
          },
        });
      }
      onError(err);
    })
    .finally(() => {
      dispatch(showSpinner(false));
    });
};
export const updateRequestInfo = (
  requestId,
  currentRequestInfo,
  values,
  onSuccess = noOp,
  onError = noOp,
  onNoChanges = noOp
) => (dispatch, getState) => {
  const newValues = {};
  Object.keys(values).forEach(key => {
    const formVal = values[key];
    const infoVal = currentRequestInfo[key];
    if (formVal !== infoVal) newValues[key] = formVal;
  });

  if (Object.keys(newValues).length === 0) {
    onNoChanges();
    return;
  }

  const integerFields = [
    'monto_solicitud',
    'plazo_meses',
    'interes',
    'cuota',
    'cuota_descuento',
    'descuento',
    'ingresos_negocio',
    'ingresos_banco',
    'ingresos_mensuales',
    'ingresos_mensuales_garante',
    'property_value',
    'otros_ingresos',
  ];

  integerFields.forEach(iF => {
    if (Object.prototype.hasOwnProperty.call(newValues, iF) && !newValues[iF])
      newValues[iF] = 0;
  });

  const onSuccessUpdate = res => {
    const { data: info } = res.data;
    dispatch(successRequestUpdateNotification());
    onSuccess(info);

    if (Object.prototype.hasOwnProperty.call(newValues, 'formsituacion')) {
      const newFormSituacion = newValues.formsituacion;
      if (newFormSituacion === SIGNED || newFormSituacion === DISBURSED) {
        const { name } = getState().user;
        // const previousRequestStatusText =
        //   requestStatusText[currentRequestInfo.formsituacion];
        const newRequestStatusText = requestStatusText[newFormSituacion];
        RequestsService.updateRequestNote(
          requestId,
          `Estado de la solicitud cambiado a ${newRequestStatusText}.`,
          true,
          name
        );
      }
    }
  };
  const onErrorUpdate = err => {
    onError(err);
    dispatch(errorRequestUpdateNotification());
  };
  dispatch(updateRequest(requestId, newValues, onSuccessUpdate, onErrorUpdate));
};

export const submitRatingForm = (
  requestId,
  currentRequestInfo,
  values
) => dispatch => {
  const successCb = info => {
    dispatch(initLoanInfoModal(info));
  };
  const { adjustedIncome, dueOther, propertyValue, score, interes } = values;

  const requestValues = {
    verified_monthly_income: adjustedIncome,
    datacredito_score: score,
    property_value: propertyValue,
    monthly_fixed_costs: dueOther,
    interes: interes / 100,
  };

  dispatch(
    updateRequestInfo(requestId, currentRequestInfo, requestValues, successCb)
  );
};

export const submitLoanModalAndClose = () => (dispatch, getState) => {
  const { loanInfoModal, user } = getState();
  const { requestInfo, formRequestInfo } = loanInfoModal;

  const onSuccessUpdate = () => {
    dispatch(closeLoanModal());
  };

  // TODO: SINCE RATING FORM ALSO USES UPDATE LOAN APPLICATION ENDPOINT, THIS VALIDATION HAS TO BE DONE HERE INSTEAD OF updateRequestInfo action
  if (!user.permissions.includes(CAN_EDIT_LOAN_APPLICATION)) {
    onSuccessUpdate();
    return;
  }

  const { id: requestId } = formRequestInfo;

  dispatch(
    updateRequestInfo(
      requestId,
      requestInfo,
      formRequestInfo,
      onSuccessUpdate,
      undefined,
      onSuccessUpdate
    )
  );
};

// TODO: IMPLEMENT REDUX FORM FOR EDIT REQUEST FORM SO WE CAN REMOTELY SUBMIT THIS FORM WITH REDUX FORM ACTIONS INSTEAD OF KEEP DOING IT THIS WAY
export const submitLoanModal = () => (dispatch, getState) => {
  const { loanInfoModal, user } = getState();
  const { requestInfo, formRequestInfo } = loanInfoModal;

  // TODO: SINCE RATING FORM ALSO USES UPDATE LOAN APPLICATION ENDPOINT, THIS VALIDATION HAS TO BE DONE HERE INSTEAD OF updateRequestInfo action
  if (!user.permissions.includes(CAN_EDIT_LOAN_APPLICATION)) return;

  const { id: requestId } = formRequestInfo;

  const onSuccessUpdate = info => {
    dispatch(initLoanInfoModal(info));
  };

  dispatch(
    updateRequestInfo(requestId, requestInfo, formRequestInfo, onSuccessUpdate)
  );
};
