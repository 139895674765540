export const DISTRITO_NACIONAL = 'Distrito Nacional';
export const SANTO_DOMINGO = 'Santo Domingo';
export const AZUA = 'Azua';
export const BARAHONA = 'Barahona';
export const COTUI = 'Cotui';
export const DAJABON = 'Dajabón';
export const ELIAS_PINA = 'Elias Piña';
export const DUARTE = 'Duarte';
export const HERMANAS_MIRABAL = 'Hermanas Mirabal';
export const LA_ROMANA = 'La Romana';
export const LA_VEGA = 'La Vega';
export const MONSENOR_NOUEL = 'Monseñor Nouel';
export const MONTE_CRISTI = 'Monte Cristi';
export const MONTE_PLATA = 'Monte Plata';
export const NEYBA = 'Neyba';
export const PERAVIA = 'Peravia';
export const PUERTO_PLATA = 'Puerto Plata';
export const SAN_JUAN = 'San Juan';
export const SAMANA = 'Samaná';
export const SANCHEZ_RAMIREZ = 'Sánchez Ramírez';
export const SAN_CRISTOBAL = 'San Cristóbal';
export const SAN_JOSE_DE_OCOA = 'San José de Ocoa';
export const SAN_PEDRO_DE_MACORIS = 'San Pedro de Macorís';
export const SANTIAGO = 'Santiago';
export const SANTIAGO_RODRIGUEZ = 'Santiago Rodríguez';
export const VALVERDE = 'Valverde';
export const BAHORUCO = 'Bahoruco';
export const EL_SEIBO = 'El Seibo';
export const ESPAILLAT = 'Espaillat';
export const HATO_MAYOR = 'Hato Mayor';
export const INDEPENDENCIA = 'Independencia';
export const LA_ALTAGRACIA = 'La Altagracia';
export const MARIA_TRINIDAD_SANCHEZ = 'María Trinidad Sánchez';
export const PEDERNALES = 'Pedernales';
