import {
  INIT_LOAN_INFO_MODAL,
  RESET_LOAN_MODAL,
  UPDATE_FORM_REQUEST_INFO,
  SET_LOAN,
} from '../actions/types';

const initialState = {
  loanId: undefined,
  // TODO: This has to be done with user type or permissions!
  fromInsurance: false,
  requestInfo: {},
  formRequestInfo: {},
  loan: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_LOAN_INFO_MODAL: {
      const { requestInfo, formRequestInfo } = action;
      return {
        ...state,
        requestInfo: {
          ...requestInfo,
        },
        formRequestInfo: {
          ...formRequestInfo,
        },
      };
    }
    case 'LOAN_MODAL_FOR_INSURANCE':
      return {
        ...state,
        fromInsurance: action.forInsurance,
      };
    case RESET_LOAN_MODAL:
      return {
        ...initialState,
      };
    case UPDATE_FORM_REQUEST_INFO: {
      const { name, value } = action;
      return {
        ...state,
        formRequestInfo: {
          ...state.formRequestInfo,
          [name]: value,
        },
      };
    }
    case SET_LOAN:
      return {
        ...state,
        loan: {
          ...action.loan,
        },
      };
    default:
      return state;
  }
};
