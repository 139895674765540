import {
  AUTH_START, AUTH_FAIL, AUTH_SUCCESS, AUTH_LOGOUT,
} from '../actions/authenticationTypes';

export const authInitialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
};

export default (state = authInitialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return { ...state, error: null, loading: true };
    case AUTH_SUCCESS: {
      const { token } = action;
      return {
        ...state,
        token,
        error: null,
        loading: false,
      };
    }

    case AUTH_FAIL:
      return { ...state, error: action.error, loading: false };
    case AUTH_LOGOUT:
      return { ...state, token: null, userId: null };
    default:
      return state;
  }
};
