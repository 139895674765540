// import MovementStatus from 'utils/constants/MovementStatus';
import { Roles } from 'components/HOC/Authorize';

// import Permissions from '../utils/constants/Permissions'; // loanApplicationsPermissions, // PermissionsEnum,

// const {
//   CAN_READ_LEGAL_LOANS,
//   CAN_READ_LOANS,
//   CAN_READ_PROFILES,
//   CAN_READ_PERMISSIONS,
//   CAN_CREATE_PROFILE,
//   CAN_CREATE_PERMISSIONS,
//   CAN_READ_MOVEMENTS,
//   CAN_READ_MOVEMENTS_SUMMARY,
//   CAN_READ_BANK_ACCOUNTS,
//   CAN_READ_LOAN_APPLICATIONS_IN_GENERAL,
//   CAN_READ_LOAN_APPLICATIONS_IN_VALIDATION,
//   CAN_READ_LOAN_APPLICATIONS_DASHBOARD,
//   CAN_READ_BROKERS,
// } = PermissionsEnum;

const urls = [
  {
    title: 'Navigation',
    items: [
      {
        pathname: '/dashboard',
        url: '/dashboard',
        icon: 'sli-screen-desktop',
        title: 'Dashboard',
        private: false,
        mandatoryPermissions: [],
        permissions: [],
        userPermissions: [],
        items: [],
      },
      // {
      //   icon: 'sli-menu',
      //   title: 'Solicitudes',
      //   private: false,
      //   items: [
      //     {
      //       pathname: '/solicitudes',
      //       url: '/solicitudes?page=1',
      //       title: 'General',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_LOAN_APPLICATIONS_IN_GENERAL],
      //       permissions: [...loanApplicationsPermissions],
      //     },
      //     {
      //       pathname: '/solicitudes/archivados',
      //       url: '/solicitudes/archivados?page=1',
      //       title: 'Archivados',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_LOAN_APPLICATIONS_IN_GENERAL],
      //       permissions: [...loanApplicationsPermissions],
      //     },
      //     {
      //       pathname: '/validacion',
      //       url: '/validacion?page=1',
      //       title: 'Validación',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_LOAN_APPLICATIONS_IN_VALIDATION],
      //       permissions: [...loanApplicationsPermissions],
      //     },
      //   ],
      // },
      // {
      //   icon: 'sli-menu',
      //   title: 'Préstamos',
      //   private: false,
      //   items: [
      //     {
      //       pathname: '/prestamos',
      //       url: '/prestamos?page=1',
      //       title: 'iBAN ONLINE',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_READ_LOANS],
      //     },
      //     {
      //       pathname: '/legal-prestamos',
      //       url: '/legal-prestamos',
      //       title: 'INSURANCE DMG',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_READ_LEGAL_LOANS],
      //     },
      //   ],
      // },
      // {
      //   icon: 'sli-menu',
      //   title: 'Panel financiero',
      //   private: false,
      //   items: [
      //     {
      //       pathname: '/movimientos',
      //       url: '/movimientos',
      //       title: 'Movimientos',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_READ_MOVEMENTS],
      //     },
      //     {
      //       pathname: '/resumen-movimientos',
      //       url: '/resumen-movimientos',
      //       title: 'Resumen general',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_READ_MOVEMENTS_SUMMARY],
      //     },
      //     {
      //       pathname: '/cuentas-bancarias',
      //       url: '/cuentas-bancarias',
      //       title: 'Cuentas bancarias',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_BANK_ACCOUNTS],
      //       permissions: [],
      //     },
      //   ],
      // },
      // {
      //   icon: 'sli-menu',
      //   title: 'Admin',
      //   private: false,
      //   items: [
      //     {
      //       pathname: '/permisos',
      //       url: '/permisos',
      //       title: 'Permisos',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_CREATE_PERMISSIONS, CAN_READ_PERMISSIONS],
      //     },
      //     {
      //       pathname: '/usuarios',
      //       url: '/usuarios',
      //       title: 'Usuarios',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_READ_PROFILES],
      //     },
      //     {
      //       pathname: '/crear-usuario',
      //       url: '/crear-usuario',
      //       title: 'Registro usuario',
      //       private: false,
      //       mandatoryPermissions: [],
      //       permissions: [CAN_CREATE_PROFILE],
      //     },
      //     {
      //       pathname: '/dashboard-solicitudes',
      //       url: '/dashboard-solicitudes',
      //       title: 'Dashboard de solicitudes',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_LOAN_APPLICATIONS_DASHBOARD],
      //       permissions: [],
      //     },
      //     {
      //       pathname: '/corredores',
      //       url: '/corredores',
      //       title: 'Corredores',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_BROKERS],
      //       permissions: [],
      //     },
      //   ],
      // },
      // {
      //   icon: 'sli-menu',
      //   title: 'Investment Panel',
      //   private: false,
      //   items: [
      //     {
      //       pathname: '/products',
      //       url: '/products',
      //       title: 'Products',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_PRODUCTS.value],
      //       permissions: [],
      //     },
      //     {
      //       pathname: '/users',
      //       url: '/users',
      //       title: 'Users',
      //       private: false,
      //       mandatoryPermissions: [CAN_READ_WALLET_USERS.value],
      //       permissions: [],
      //     },
      //   ],
      // },

      {
        pathname: '/own-wallets',
        url: '/own-wallets',
        title: 'Platform Wallets',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.ADVISOR],
        userPermissions: [],
        items: [],
        icon: 'sli-wallet',
      },
      {
        pathname: '/transactions-distributor-platform',
        url: '/transactions-distributor-platform',
        title: 'Platform Transactions',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.ADVISOR],
        userPermissions: [],
        items: [],
        icon: 'sli-energy',
      },
      {
        pathname: '/distributor-platforms',
        url: '/distributor-platforms',
        title: 'Distributor Platforms',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: [],
        items: [],
        icon: 'sli-people',
      },
      {
        pathname: '/distributor-admins',
        url: '/distributor-admins',
        title: 'Distributor Admins',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: [],
        items: [],
        icon: 'sli-people',
      },
      {
        pathname: '/users',
        url: '/users',
        title: 'Users',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS, Roles.ADVISOR],
        userPermissions: [],
        items: [],
        icon: 'sli-people',
      },

      {
        pathname: '/platform-wallets',
        url: '/platform-wallets',
        title: 'D. Platform wallets',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: [],
        items: [],
        icon: 'sli-wallet',
      },
      {
        pathname: '/user-wallets',
        url: '/user-wallets',
        title: 'User wallets',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS, Roles.ADVISOR],
        userPermissions: [],
        items: [],
        icon: 'sli-wallet',
      },
      {
        pathname: '/transactions',
        url: '/transactions',
        title: 'User transactions',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS, Roles.ADVISOR],
        userPermissions: [],
        items: [],
        icon: 'sli-energy',
      },
      {
        pathname: '/backend-banks',
        url: '/backend-banks',
        title: 'Back-end banks',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: [],
        items: [],
        icon: 'sli-globe',
      },
      {
        pathname: '/admins',
        url: '/admins',
        title: 'Teapayment Admins',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: ['dino:api:ops:read', '*'],
        items: [],
        icon: 'sli-people',
      },
      {
        pathname: '/reconciliations/external-balance',
        url: '/reconciliations/external-balance',
        title: 'Reconciliations',
        private: false,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: ['dino:api:ops:read', '*'],
        items: [],
        icon: 'sli-book-open',
      },
      {
        pathname: '/user-screening/pending',
        url: '/user-screening/pending',
        title: 'User Screening',
        private: true,
        mandatoryPermissions: [],
        permissions: [Roles.OPERATIONS],
        userPermissions: ['dino:api:ops:read', '*'],
        items: [],
        icon: 'sli-globe',
      },
    ],
  },
];
// eslint-disable-next-line import/prefer-default-export
export function navigation(state = Array.from(urls), action) {
  switch (action.type) {
    case 'SET_NAVIGATION':
      return { ...state, ...action.navigation };
    default:
      return state;
  }
}
