import AsyncComponent from '../../components/HOC/AsyncComponent';

const AsyncProducts = AsyncComponent(() => import('./Products'));
const AsyncWalletUsers = AsyncComponent(() => import('./Users'));
const AsyncDistributorAdmins = AsyncComponent(() =>
  import('./DistributorAdmins')
);
const AsyncDistributorPlatforms = AsyncComponent(() =>
  import('./DistributorPlatforms')
);
const AsyncUserProducts = AsyncComponent(() => import('./UserProducts'));

export {
  AsyncProducts,
  AsyncWalletUsers,
  AsyncDistributorAdmins,
  AsyncDistributorPlatforms,
  AsyncUserProducts,
};
