import React from 'react';

import moment from 'moment';
import { querySearchParse } from '../../utils/helpers/general';

export default WrappedComponent =>
  class extends React.Component {
    getCurrentPage = () => {
      const params = querySearchParse(this.props.location.search);
      const pageParam = params.page;
      return pageParam ? parseInt(pageParam, 10) - 1 : 0;
    };

    getFilter = () => {
      const query = querySearchParse(this.props.location.search);

      const parsedFilter = Object.keys(query).reduce((prev, fk) => {
        let filterValue = query[fk];
        let keyName = fk;

        if (fk.includes('~')) {
          const [attributeName, operator, customOperator] = fk.split('~');

          switch (operator) {
            case 'like':
            case 'ilike':
              filterValue = `%${filterValue}%`;
              break;
            default:
              break;
          }
          if (customOperator) {
            switch (customOperator) {
              case 'dateFrom':
                filterValue =
                  moment(new Date(filterValue))
                    .startOf('day')
                    .unix() * 1000;

                break;
              case 'date':
              case 'dateTo':
                filterValue =
                  moment(new Date(filterValue))
                    .endOf('day')
                    .unix() * 1000;
                break;
              default:
                break;
            }
            keyName = `${attributeName}~${operator}`;
          }
        }
        return {
          ...prev,
          [keyName]: filterValue,
        };
      }, {});
      delete parsedFilter.page;
      return parsedFilter;
    };

    render() {
      const page = this.getCurrentPage();
      return (
        <WrappedComponent
          page={page}
          filter={this.getFilter()}
          {...this.props}
        />
      );
    }
  };
