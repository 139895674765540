export const HISTORY_NOTES_MODAL = 'historyNotesModal';
export const LOAN_INFO_MODAL = 'loanInfoModal';
export const BLOCK_USER_MODAL = 'confirmBlockUserModal';
export const BLOCK_PLATFORM_MODAL = 'confirmBlockPlatformModal';
export const approveRejectBankDetailsModalName =
  'approveRejectBankDetailsModalName';
export const MOVEMENT_DETAILS_MODAL = 'movementDetailsModalName';
export const ADD_FUNDS_MODAL = 'addFundsModalName';
export const RENEW_CLOSE_MODAL = 'renewCloseModalName';
export const BUSINESS_KYC_MODAL = 'incorporationKycModalName';
export const BUSINESS_FILE_MODAL = 'incorporationFileModalName';
export const addUserModalName = 'addUserModalName';
export const addDistributorAdminModalName = 'addDistributorAdminModalName';
export const addDistributorPlatformModalName =
  'addDistributorPlatformModalName';
export const addAdminModalName = 'addAdminModalName';
export const addWalletModalName = 'addWalletModalName';
export const addBankAccountModalName = 'addBankAccountModalName';
export const addMandateModalName = 'addMandateModalName';
export const payinModalName = 'payinModalName';
export const payoutModalName = 'payoutModalName';
export const transferModalName = 'transferModalName';
export const REVIEW_MODAL = 'REVIEW_MODAL';
export const OVERRIDE_KYC_MODAL = 'overrideKycModal';
export const EDIT_DOCUMENT_META = 'editDocumentMetadata';
export const VERIFY_PROOF_OF_ADDREES_MODAL = 'VERIFY_PROOF_OF_ADDREES_MODAL';
export const RESET_KYC_MODAL = 'resetKycModal';
export const RUN_KYC_MANUALLY_MODAL = 'RUN_KYC_MANUALLY_MODAL';
export const confirmMatchUserScreeningModalName =
  'confirmMatchScreeningMatchModal';
export const confirmClearUserScreeningModalName =
  'confirmClearScreeningMatchModal';
