export const storeKeys = objectToStore => {
  Object.keys(objectToStore).forEach(key => {
    localStorage.setItem(key, objectToStore[key]);
  });
};
export const getKeys = (...args) => {
  let obj = {};
  args.forEach(key => {
    obj[key] = localStorage.getItem(key);
  });
  return obj;
};

export const removeKeys = (...keys) => {
  keys.forEach(key => {
    localStorage.removeItem(key);
  });
};
