import {
  createPofileUrl,
  retreiveProfileByTokenUrl,
  getProfilesUrl,
  getClientAreaProfilesUrl,
  retreiveClientAreaProfileByUserIdUrl,
  retreiveProfileByUserIdUrl,
} from '../constants/endpointUrls';

import Service from './Service';

class UserService extends Service {
  createProfile(userId, email, name, partner) {
    const params = {
      email,
      name,
      partner,
    };
    return this.postRequest(createPofileUrl(userId), params);
  }

  getProfileByToken(token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return this.getRequest(retreiveProfileByTokenUrl, undefined, config);
  }

  getProfiles(page = 0) {
    const params = {
      page,
    };
    return this.getRequest(getProfilesUrl, params);
  }

  getClientAreaProfiles(filter = {}, page = 0) {
    const params = {
      ...filter,
      page,
    };
    return this.getRequest(getClientAreaProfilesUrl, params);
  }

  getProfileByUserId(userId) {
    return this.getRequest(retreiveProfileByUserIdUrl(userId));
  }

  getClientAreaProfileByUserId(userId) {
    return this.getRequest(retreiveClientAreaProfileByUserIdUrl(userId));
  }
}

export default new UserService();
