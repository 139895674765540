import {
  signUpIbanUrl,
  signInUrl,
  registrationTokenUrl
} from '../constants/endpointUrls';

import Service from './Service';

class AuthService extends Service {
  signUp(email, password) {
    const params = { email, password };
    return this.postRequest(signUpIbanUrl, params);
  }
  signIn(email, password) {
    const params = { email, password };
    return this.postRequest(signInUrl, params);
  }
  sendRegistrationToken(name, email, appName) {
    const params = { email, appName, params: { name } };
    return this.postRequest(registrationTokenUrl, params);
  }
}

export default new AuthService();
