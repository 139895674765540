// import { createBrowserHistory } from 'history';
import { createHashHistory } from 'history';

// const history = createBrowserHistory();
// export default history;
const history = createHashHistory();
export default history;
// import createHistory from 'history/createHashHistory'
// const history = createHistory()
// export default history
