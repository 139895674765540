import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { setAuthenticatedProfile } from 'actions/userActionCreators';
import { compose } from 'redux';
import getAdvisorOwnDetails from 'services/api/distributor/getAdvisorOwnDetails';
import getOpsOwnDetails from 'services/api/ops/getOpsOwnDetails';

import withAppComponent from 'components/HOC/AppComponent';
import { withPaged } from 'components/HOC';

// import { isEqual } from 'lodash';
// import { formatCurrency } from 'utils/extensions/NumberExtensions';
// import { TableLayout } from 'components/Layout';
// import getEmiWalletList from 'services/api/wallet/getEmiWalletList';
// import { CanAccessOperationsActions } from 'components/HOC/Authorize';
import Jumbotron1 from '../elements/Jumbotron1';

// eslint-disable-next-line react/prefer-stateless-function
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.fields = ['ID', 'Balance'];
    this.state = {
      // paging: {
      // hasNextPage: false,
      // },
      wallets: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem('isLoggedIn') === '1') {
      if (localStorage.getItem('hasValidatedOtp') === '1') {
        const { role, setAuthProfile } = this.props;
        switch (role) {
          case 'advisor': {
            const resAd = await getAdvisorOwnDetails();
            setAuthProfile(resAd.data.data);
            break;
          }
          case 'operations': {
            try {
              const resAd = await getOpsOwnDetails();
              setAuthProfile(resAd.data.data);
            } catch (error) {
              const errorMessage = error.response.data.meta.errorMessage;
              if (errorMessage.toLowerCase().includes('dormant')) {
                this.props.history.replace('/dormant');
                return;
              }
            }
            break;
          }
          default:
            break;
        }
        this.props.history.replace('/dashboard');
      } else {
        this.props.history.replace('/otp');
      }
    }
    // this.getEmiWallets();
  }

  // componentDidUpdate(prevProps) {
  // const { page, filter } = this.props;
  // if (prevProps.page !== page || !isEqual(prevProps.filter, filter))
  //   this.getEmiWallets();
  // }

  // getEmiWallets = async () => {
  //   const { role } = this.props;
  //   if (role === 'operations') {
  //     this.props.handleAsyncRequest(async () => {
  //       const { page, filter } = this.props;
  //
  //       const response = await getEmiWalletList({
  //         page,
  //         ...filter,
  //         sortBy: 'cvl',
  //         includeToBeProcessed: true,
  //       });
  //       // const { values: wallets, ...paging } = response.data.data;
  //       const { values: wallets } = response.data.data;
  //
  //       // this.setState({ paging, wallets });
  //       this.setState({ wallets });
  //     });
  //   }
  // };

  render() {
    // const headerFields = this.fields.map((f, i) => (
    //   <th key={`product-header-field-${i}`}>{f}</th>
    // ));

    // const walletRows = this.state.wallets.map((u, i) => {
    //   const { id, balance, currencyAlpha3Code } = u;
    //   return (
    //     <tr key={`wallet-row-${i}`}>
    //       <td>
    //         <Link to={`/user-wallets/edit/${id}/details`}>{id}</Link>
    //       </td>
    //       <td>{formatCurrency(balance, { currencyAlpha3Code })}</td>
    //     </tr>
    //   );
    // });

    return (
      <div>
        <Jumbotron1 />
        <div className="row">
          <div className="col">
            {/* <Widget title="Inicio"> */}
            {/* <NotesGenerator /> */}

            {/* <div className="row">
                <div className="col">
                  <div className="card-columns">
                    <div className="card">
                      <div className="card-block">
                        <h4 className="card-title">Card title</h4>
                        <p className="card-text">
                          This is a wider card with supporting text below as a
                          natural lead-in to additional content. This card has
                          even longer content than the first to show that equal
                          height action.
                        </p>
                        <p className="card-text">
                          <small className="text-muted">
                            Last updated 3 mins ago
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* </Widget> */}
            {/*<CanAccessOperationsActions>
              <div
                style={{
                  paddingLeft: '10px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  fontWeight: 'bold',
                }}
              >
                Teapayment EMI operational wallets
              </div>
              <div className="table-responsive">
                <TableLayout
                  className="table table-striped"
                  fields={this.fields}
                >
                  <thead>
                    <tr>{headerFields}</tr>
                  </thead>
                  <tbody>{walletRows}</tbody>
                </TableLayout>
              </div>
            </CanAccessOperationsActions>*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  role: state.user.role,
});

const actions = {
  setAuthProfile: setAuthenticatedProfile,
};

export default compose(
  withPaged,
  withRouter,
  withAppComponent,
  connect(mapStateToProps, actions)
)(Dashboard);
