import jsonp from 'jsonp';

export const notificationConfig = (
  title,
  message,
  status = 'success',
  dismissAfter = 5000,
  closeButton = false,
) => ({
  title,
  message,
  status,
  dismissAfter,
  dismissible: true,
  position: 'tc',
  closeButton,
  allowHTML: true,
});

export const logException = (exception, logExtra = {}) => {
  const { REACT_APP_ENABLE_LOG_ROCKET: enableLog } = process.env;
  const { LogRocket } = window;
  if (enableLog === 'true' && LogRocket) {
    LogRocket.captureException(exception, {
      tags: {
        // additional data to be grouped as "tags"
        subscription: 'pro',
      },
      extra: {
        // additional arbitrary data associated with the event
        ...logExtra,
      },
    });
  }
};

export const querySearchParse = (search) => {
  let params = {};
  if (!search) return params;

  const query = search.substring(1);

  query.split('&').forEach((s) => {
    const [param, value] = s.split('=');
    params = {
      ...params,
      [param]: decodeURIComponent(value),
    };
  });
  return params;
};

/**
 * Performs a look up with the values of first array, in the second array.
 * @param {Array} first First array to look up
 * @param {Array} second Second array to look up
 * @returns {boolean} True if any of the values in first array are in second array.
 */
export const isAnyOfFirstInSecond = (first, second) => {
  for (let i = 0; i < first.length; i++) if (second.includes(first[i])) return true;
  return false;
};

/**
 * Performs a look up with the values of first array, in the second array.
 * @param {Array} first First array to look up
 * @param {Array} second Second array to look up
 * @returns {boolean} True if all of the values in first array are in second array.
 */
export const isAllOfFirstInSecond = (first, second) => {
  for (let i = 0; i < first.length; i++) if (!second.includes(first[i])) return false;
  return true;
};
// https://codepen.io/ahmady09/pen/PNejyN?editors=0010
// By https://codepen.io/ahmady09/
/* random qoutes from WikiQoutes api */
export const Wikiquote = (function () {
  const wqa = {};

  const API_URL = 'https://en.wikiquote.org/w/api.php';

  /**
   * Query based on "titles" parameter and return page id.
   * If multiple page ids are returned, choose the first one.
   * Query includes "redirects" option to automatically traverse redirects.
   * All words will be capitalized as this generally yields more consistent results.
   */
  wqa.queryTitles = function (titles, success = () => { }, error = () => { }) {
    const onSuccess = (err, result) => {
      // console.log('titles result', result);
      const pages = result.query.pages;
      let pageId = -1;
      for (const p in pages) {
        const page = pages[p];
        // api can return invalid recrods, these are marked as "missing"
        if (!('missing' in page)) {
          pageId = page.pageid;
          break;
        }
      }
      if (pageId > 0) {
        success(pageId);
      } else {
        error('No results');
      }
    };
    jsonp(
      API_URL,
      {
        param: `format=json&action=query&titles=${titles}&redirects=&callback`,
      },
      onSuccess,
    );
  };

  /**
   * Get the sections for a given page.
   * This makes parsing for quotes more manageable.
   * Returns an array of all "1.x" sections as these usually contain the quotes.
   * If no 1.x sections exists, returns section 1. Returns the titles that were used
   * in case there is a redirect.
   */
  wqa.getSectionsForPage = function (pageId, success, error) {
    const onSuccess = (err, result) => {
      // console.log('sections for pages', result);
      const sectionArray = [];
      const sections = result.parse.sections;
      for (const s in sections) {
        const splitNum = sections[s].number.split('.');
        if (splitNum.length > 1 && splitNum[0] === '1') {
          sectionArray.push(sections[s].index);
        }
      }
      // Use section 1 if there are no "1.x" sections
      if (sectionArray.length === 0) {
        sectionArray.push('1');
      }
      success({ titles: result.parse.title, sections: sectionArray });
    };
    jsonp(
      API_URL,
      {
        param: `format=json&action=parse&prop=sections&pageid=${pageId}&callback`,
      },
      onSuccess,
    );
  };

  /**
   * Get all quotes for a given section.  Most sections will be of the format:
   * <h3> title </h3>
   * <ul>
   *   <li>
   *     Quote text
   *     <ul>
   *       <li> additional info on the quote </li>
   *     </ul>
   *   </li>
   * <ul>
   * <ul> next quote etc... </ul>
   *
   * The quote may or may not contain sections inside <b /> tags.
   *
   * For quotes with bold sections, only the bold part is returned for brevity
   * (usually the bold part is more well known).
   * Otherwise the entire text is returned.  Returns the titles that were used
   * in case there is a redirect.
   */
  wqa.getQuotesForSection = function (pageId, sectionIndex, success, error) {
    const onSuccess = (err, result) => {
      // console.log('quotes for section', result);
      // console.log(result.parse.text['*']);

      const el = document.createElement('div');
      el.innerHTML = result.parse.text['*'];

      const lis = el.getElementsByTagName('li');
      // console.log('lis', lis);

      let quotes = [];
      for (let i = 0; i < lis.length; i++) {
        const li = lis.item(i);
        // console.log(li.id);
        if (!li.id) {
          if (li.innerText[0] === '"' || li.innerText[0] === '«') {
            let text = li.innerText;
            if (text[0] === '"') {
              text = text.substring(text.indexOf('"') + 1, text.lastIndexOf('"'));
            } else if (text[0] === '«') {
              text = text.substring(text.indexOf('«') + 1, text.lastIndexOf('»'));
            }
            quotes = quotes.concat(text);
          }
        }
      }
      success({ titles: result.parse.title, quotes });
    };
    jsonp(
      API_URL,
      {
        param: `format=json&action=parse&pageid=${pageId}&section=${sectionIndex}&noimages=&callback`,
      },
      onSuccess,
    );
  };

  /**
   * Get a random quote for the given title search.
   * This function searches for a page id for the given title, chooses a random
   * section from the list of sections for the page, and then chooses a random
   * quote from that section.  Returns the titles that were used in case there
   * is a redirect.
   */
  wqa.getRandomQuote = function (titles, success, error = () => { }) {
    const errorFunction = function (msg) {
      error(msg);
    };

    const chooseQuote = function (quotes) {
      const randomNum = Math.floor(Math.random() * quotes.quotes.length);
      success({ titles: quotes.titles, quote: quotes.quotes[randomNum] });
    };

    const getQuotes = function (pageId, sections) {
      const randomNum = Math.floor(Math.random() * sections.sections.length);
      wqa.getQuotesForSection(pageId, sections.sections[randomNum], chooseQuote, errorFunction);
    };

    const getSections = function (pageId) {
      wqa.getSectionsForPage(
        pageId,
        (sections) => {
          getQuotes(pageId, sections);
        },
        errorFunction,
      );
    };

    wqa.queryTitles(titles, getSections, errorFunction);
  };

  /**
   * Capitalize the first letter of each word
   */
  wqa.capitalizeString = function (input) {
    const inputArray = input.split(' ');
    const output = [];
    for (const s in inputArray) {
      output.push(inputArray[s].charAt(0).toUpperCase() + inputArray[s].slice(1));
    }
    return output.join(' ');
  };

  return wqa;
}());
/* random qoutes from WikiQoutes api */
