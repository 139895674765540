// Style system utility.
import { style, compose, getWidth } from 'styled-system';

export const fontSize = style({
  prop: 'fs',
  cssProperty: 'fontSize',
  key: 'fontSizes',
  scale: [12, 14, 16, 20, 24, 32, 48, 64, 72],
});

export const textColor = style({
  prop: 'clr',
  cssProperty: 'color',
  key: 'colors',
});

export const bgColor = style({
  prop: 'bg',
  cssProperty: 'backgroundColor',
  key: 'colors',
});

export const color = compose(textColor, bgColor);

export const fontWeight = style({
  prop: 'fw',
  cssProperty: 'fontWeight',
  key: 'fontWeights',
});

export const fontFamily = style({
  prop: 'font',
  cssProperty: 'fontFamily',
  key: 'fonts',
});

export const width = style({
  prop: 'w',
  cssProperty: 'width',
  transformValue: getWidth,
});
