import React from 'react';
import Flex from './Flex';

function Row({
  children,
  hAlign = undefined,
  vAlign = undefined,
  flexDirection = undefined,
  ...passProps
}) {
  return (
    <Flex
      {...passProps}
      justifyContent={hAlign}
      alignItems={vAlign}
      flexDirection={flexDirection || 'row'}
      flexWrap={['wrap', null, 'no-wrap']}
    >
      {children}
    </Flex>
  );
}

Row.defaultProps = {
  hAlign: undefined,
  vAlign: undefined,
  flexDirection: undefined,
};

export default Row;
