import AsyncComponent from '../components/HOC/AsyncComponent';
import AsyncDashboard from './Dashboard';
import AsyncDormant from './Dormant';

const AsyncLoginAdmin = AsyncComponent(() => import('./LoginAdmin'));
const AsyncLoginDistributor = AsyncComponent(() =>
  import('./LoginDistributor')
);
const AsyncOtp = AsyncComponent(() => import('./OneTimePassword'));
// const AsyncDashboard = AsyncComponent(() => import('./Dashboard'));
const AsyncTermsAndConditionsOfUse = AsyncComponent(() =>
  import('./TermsAndConditionsOfUse')
);
const AsyncLoggedId = AsyncComponent(() => import('./LoggedIn'));

export {
  AsyncLoginAdmin,
  AsyncLoginDistributor,
  AsyncOtp,
  AsyncDashboard,
  AsyncDormant,
  AsyncTermsAndConditionsOfUse,
  AsyncLoggedId,
};
