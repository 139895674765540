import React from 'react';
// import theme from 'config/theme';
import Box from './Box';

const validateColumns = n => n && n > 0 && n <= 12;

const getColumnWidth = n => (n / 12) * 100;

const getProps = columnsByScreen => {
  const columnsProps = columnsByScreen.reduce(
    (acc, columns, index) => {
      if (validateColumns(columns)) {
        const width = getColumnWidth(columns);
        acc.maxWidth.push(`${width}%`);
        acc.flex.push(`0 0 ${width}%`);

        return acc;
      }

      acc.maxWidth.push(null);
      acc.flex.push(index === 0 ? '1 1 0px' : null);

      return acc;
    },
    { flex: [], maxWidth: [] }
  );

  const props = {
    ...columnsProps,
    minWidth: 0,
  };

  return props;
};

function Column({
  children,
  xSmall = undefined,
  small = undefined,
  medium = undefined,
  large = undefined,
  xLarge = undefined,
  ...passProps
}) {
  return (
    <Box {...getProps([xSmall, small, medium, large, xLarge])} {...passProps}>
      {children}
    </Box>
  );
}

Column.defaultProps = {
  // px: theme.defaultGutter,
  xSmall: undefined,
  small: undefined,
  medium: undefined,
  large: undefined,
  xLarge: undefined,
};

export default Column;
