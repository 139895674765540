export const APPROVED = 'aceptado';
export const CANCELED = 'cancelado';
export const SIGNED = 'firmado';
export const NO_CONTACT = 'sincontactar';
export const UNABLE_TO_REACH = 'nopudecontactar';
export const TRY_RECALL = 'volverallamar';
export const DOES_NOT_WANT = 'noquiere';
export const DOES_NOT_HAVE = 'notiene';
export const PENDING = 'pendiente';
export const REJECTED = 'rechazado';
export const RECEIVE_DOC = 'recibido';
export const VALIDATE_DOC = 'validacion';
export const DISBURSED = 'disbursed';
export const PENDING_VALIDATION = 'pending-validation';

export const LoanApplicationStatus = {
  NO_CONTACT: {
    value: 'sincontactar',
    text: 'Sin contactar',
  },
  UNABLE_TO_REACH: {
    value: 'nopudecontactar',
    text: 'No pude comunicarme',
  },
  TRY_RECALL: {
    value: 'volverallamar',
    text: 'Volver a llamar',
  },
  DOES_NOT_WANT: {
    value: 'noquiere',
    text: 'No quiere',
  },
  DOES_NOT_HAVE: {
    value: 'notiene',
    text: 'No tiene',
  },
  MISTAKEN: {
    value: 'mistaken',
    text: 'Equivocado',
  },
  DOES_NOT_APPLY: {
    value: 'does-not-apply',
    text: 'No aplica',
  },
  RECEIVE_DOC: {
    value: 'recibido',
    text: 'Recibir documentación',
  },
  PENDING_VALIDATION: {
    value: 'pending-validation',
    text: 'En revisión, pendiente de validación',
  },
  VALIDATE_DOC: {
    value: 'validacion',
    text: 'Validar documentación',
  },
  PRE_APROVED: {
    value: 'pre-approved',
    text: 'Pre-aprobado',
  },
  PENDING: {
    value: 'pendiente',
    text: 'Pendiente',
  },
  REJECTED: {
    value: 'rechazado',
    text: 'Rechazado',
  },
  CANCELED: {
    value: 'cancelado',
    text: 'Cancelado',
  },
  APPROVED: {
    value: 'aceptado',
    text: 'Aprobado',
  },
  SIGNED: {
    value: 'firmado',
    text: 'Firmado',
  },
  DISBURSED: {
    value: 'disbursed',
    text: 'Desembolsado',
  },
};

const LoanApplicationStatusKeys = Object.keys(LoanApplicationStatus);

export const requestStatusOptions = LoanApplicationStatusKeys.map(
  p => LoanApplicationStatus[p]
);

export const LoanApplicationStatusTextFromValue = LoanApplicationStatusKeys.reduce(
  (prev, current) => {
    const loanApplicationStatus = LoanApplicationStatus[current];
    return {
      ...prev,
      [loanApplicationStatus.value]: loanApplicationStatus.text,
    };
  },
  {}
);

export default LoanApplicationStatus;
