import requestHandler from '../requestHandler';

const requestOtp = accessToken =>
  requestHandler.post(`/api/v1/auth/twoFactor/request`, {
    accessToken,
  });

const sendOtp = (accessToken, token) =>
  requestHandler.post(`/api/v1/auth/twoFactor`, {
    accessToken,
    token,
  });

const updateTwoFactor = (oldAccessToken, newAccessToken) =>
  requestHandler.put(`/api/v1/auth/twoFactor`, {
    oldAccessToken,
    newAccessToken,
  });

export { requestOtp, sendOtp, updateTwoFactor };
