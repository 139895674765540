import { toast } from 'react-toastify';

export const customNotification = (_title, message, type) =>
  toast(message, { type });

export const successRequestUpdateNotification = () =>
  customNotification('Satisfactorio', 'Información actualizada!', 'success');

export const errorRequestUpdateNotification = () =>
  customNotification('Error', 'No se pudo actualizar la información!', 'error');

export const successDiscountAppliedNotification = () =>
  customNotification(
    'Satisfactorio',
    'Descuento aplicado satisfactoriamente!',
    'success'
  );
export const successGeneralNotification = () =>
  customNotification('Satisfactorio', 'Información actualizada!', 'success');
export const errorGeneralNotification = () =>
  customNotification('Error', 'Unexpected error!', 'error');

export const errorNotification = message =>
  customNotification('Error', message, 'error');

export const contractCreatedNotification = () =>
  customNotification('Satisfactorio', 'Contrato creado!', 'success');
export const successNotification = message =>
  customNotification('Satisfactorio', message, 'success');
export const loginError = () =>
  customNotification('Error', 'Wrong email or password', 'error');

export const updateAvailableNotification = () =>
  toast.info(
    'Actualización disponible!\nExiste una nueva actualización de la aplicación! Refrescar la aplicación para obtener los últimos cambios.'
  );
