import axios from 'axios';
import { logException } from '../helpers/general';

const { get, post, patch } = axios;
class Service {
  /*eslint-disable */
  _handleFail(exception, logExtra) {
    logException(exception, logExtra);
  }

  getDefaultConfig() {
    const token = localStorage.getItem('token');
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  /* eslint-enable */
  getRequest(url, params = {}, config = {}, logService = {}) {
    return (success = null, complete = null, fail = null, logClient = {}) => {
      get(url, { params, ...this.getDefaultConfig(), ...config })
        .then(success)
        .catch(err => {
          this._handleFail(err, { ...logService, ...logClient });
          if (fail && typeof fail === 'function') fail(err);
        })
        .finally(complete);
    };
  }

  postRequest(url, params = {}, config = {}, logService = {}) {
    return (success = null, complete = null, fail = null, logClient = {}) => {
      post(url, params, { ...this.getDefaultConfig(), ...config })
        .then(success)
        .catch(err => {
          this._handleFail(err, { ...logService, ...logClient });
          if (fail && typeof fail === 'function') fail(err);
        })
        .finally(complete);
    };
  }

  patchRequest(url, params = {}, config = {}, logService = {}) {
    return (success = null, complete = null, fail = null, logClient = {}) => {
      patch(url, params, { ...this.getDefaultConfig(), ...config })
        .then(success)
        .catch(err => {
          this._handleFail(err, { ...logService, ...logClient });
          if (fail && typeof fail === 'function') fail(err);
        })
        .finally(complete);
    };
  }
}

export default Service;
