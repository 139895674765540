export const PermissionType = {
  ADMIN: 'ADMIN',
  PROCESS: 'PROCESS',
  FINANCIAL_PANEL: 'FINANCIAL_PANEL',
  INVESTMENT: 'INVESTMENT',
  REPORT: 'REPORT',
  NOT_CLASSIFIED: 'NOT_CLASSIFIED',
};
const { ADMIN, PROCESS, FINANCIAL_PANEL, REPORT, INVESTMENT } = PermissionType;

const Presets = {
  ADMIN_PRESET: 'ADMIN',
  CALL_CENTER_PRESET: 'CALL_CENTER',
  VALIDATION_PRESET: 'VALIDATION',
  PRE_VALIDATION_PRESET: 'PRE_VALIDATION',
  DEV_PRESET: 'DEV',
};

const {
  ADMIN_PRESET,
  CALL_CENTER_PRESET,
  VALIDATION_PRESET,
  PRE_VALIDATION_PRESET,
  DEV_PRESET,
} = Presets;

const Permissions = {
  CAN_GRANT_PERMISSIONS: {
    value: 'can_grant_permissions',
    text: 'Puede otorgar permisos',
    presets: [ADMIN_PRESET, DEV_PRESET],
    group: ADMIN,
    order: 0,
  },
  CAN_REVOKE_PERMISSIONS: {
    value: 'can_revoke_permissions',
    text: 'Puede revocar permisos',
    presets: [ADMIN_PRESET, DEV_PRESET],
    group: ADMIN,
    order: 1,
  },
  CAN_CREATE_PERMISSIONS: {
    value: 'can_create_permissions',
    text: 'Puede crear permisos',
    presets: [DEV_PRESET],
    group: ADMIN,
    order: 2,
  },
  CAN_READ_PERMISSIONS: {
    value: 'can_read_permissions',
    text: 'Puede ver permisos',
    presets: [ADMIN_PRESET, DEV_PRESET],
    group: ADMIN,
    order: 3,
  },
  CAN_READ_PROFILES: {
    value: 'can_read_profiles',
    text: 'Puede ver usuarios',
    presets: [ADMIN_PRESET, DEV_PRESET],
    group: ADMIN,
    order: 4,
  },
  CAN_CREATE_PROFILE: {
    value: 'can_create_profile',
    text: 'Puede crear usuarios',
    presets: [ADMIN_PRESET, DEV_PRESET],
    group: ADMIN,
    order: 5,
  },
  CAN_READ_LOAN_APPLICATIONS_DASHBOARD: {
    value: 'can_read_loan_applications_dashboard',
    text: 'Puede ver dashboard de solicitudes',
    presets: [DEV_PRESET],
    group: ADMIN,
    order: 5.1,
  },
  CAN_READ_BROKERS: {
    value: 'can_read_brokers',
    text: 'Puede ver corredores',
    presets: [ADMIN_PRESET, DEV_PRESET],
    group: ADMIN,
    order: 5.2,
  },
  CAN_READ_LOAN_APPLICATIONS_IN_GENERAL: {
    value: 'can_read_loan_applications_in_general',
    text: 'Ver solicitudes hipotecarias en general',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 6,
  },
  CAN_READ_LOAN_APPLICATIONS_IN_VALIDATION: {
    value: 'can_read_loan_applications_in_validation',
    text: 'Ver solicitudes personales en validación',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 6,
  },
  CAN_READ_HOME_LOAN_APPLICATIONS: {
    value: 'can_read_home_loan_applications',
    text: 'Puede ver solicitudes de hogar',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 7.1,
  },
  CAN_READ_SOLUTION_LOAN_APPLICATIONS: {
    value: 'can_read_solution_loan_applications',
    text: 'Puede ver solicitudes solidarias',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 7.3,
  },
  CAN_READ_SOCIAL_LOAN_APPLICATIONS: {
    value: 'can_read_social_loan_applications',
    text: 'Puede ver solicitudes sociales',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 7.4,
  },
  CAN_READ_BUSINESS_LOAN_APPLICATIONS: {
    value: 'can_read_business_loan_applications',
    text: 'Ver solicitudes de negocio',
    group: PROCESS,
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    order: 8,
  },
  CAN_READ_NOT_CLASSIFIED_APPLICATIONS: {
    value: 'can_read_not_classified_applications',
    text: 'Ver solicitudes no clasificadas',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 10,
  },
  CAN_READ_OWN_LOAN_APPLICATIONS: {
    value: 'can_read_own_loan_applications',
    text: 'Ver solicitudes propias',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 11,
  },
  CAN_READ_OTHERS_LOAN_APPLICATIONS: {
    value: 'can_read_others_loan_applications',
    text: 'Ver solicitudes de otros',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 12,
  },
  CAN_BE_ASSIGNED_LOAN_APPLICATIONS_AUTOMATICALLY: {
    value: 'can_be_assigned_loan_applications_automatically',
    text: 'Puede recibir solicitudes automáticamente',
    presets: [CALL_CENTER_PRESET],
    group: PROCESS,
    order: 12,
  },
  CAN_READ_LOANS: {
    value: 'can_read_loans',
    text: 'Puede ver préstamos',
    presets: [ADMIN_PRESET, DEV_PRESET, VALIDATION_PRESET],
    group: PROCESS,
    order: 13,
  },
  CAN_READ_LEGAL_LOANS: {
    value: 'can_read_legal_loans',
    text: 'Puede ver préstamos legales',
    presets: [ADMIN_PRESET, DEV_PRESET, VALIDATION_PRESET],
    group: PROCESS,
    order: 14,
  },

  CAN_CREATE_LOANS: {
    value: 'can_create_loans',
    text: 'Puede crear prestamos',
    presets: [ADMIN_PRESET, DEV_PRESET, VALIDATION_PRESET],
    group: PROCESS,
    order: 15,
  },
  CAN_CREATE_PAYMENTS: {
    value: 'can_create_payments',
    text: 'Puede hacer pagos',
    presets: [ADMIN_PRESET, DEV_PRESET, VALIDATION_PRESET],
    group: PROCESS,
    order: 16,
  },
  CAN_APPLY_DISCOUNT: {
    value: 'can_apply_discount',
    text: 'Puede aplicar descuentos',
    presets: [ADMIN_PRESET, DEV_PRESET, VALIDATION_PRESET],
    group: PROCESS,
    order: 17,
  },
  CAN_EDIT_LOAN_APPLICATION: {
    value: 'can_edit_loan_application',
    text: 'Puede editar solicitudes',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 18,
  },

  CAN_ARCHIVE_LOAN_APPLICATION: {
    value: 'can_archive_loan_application',
    text: 'Puede archivar solicitudes',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 19,
  },
  CAN_CREATE_LOAN_APPLICATION_NOTE: {
    value: 'can_create_loan_application_note',
    text: 'Puede crear notas',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 20,
  },
  CAN_READ_LOAN_APPLICATION_NOTES: {
    value: 'can_read_loan_application_notes',
    text: 'Puede ver notas',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      CALL_CENTER_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 21,
  },
  CAN_EDIT_RATING: {
    value: 'can_edit_rating',
    text: 'Puede editar rating',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: PROCESS,
    order: 22,
  },
  CAN_CREATE_CONTRACT: {
    value: 'can_create_contract',
    text: 'Puede crear contratos',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: REPORT,
    order: 23,
  },
  CAN_CREATE_PROMISSORY_NOTE: {
    value: 'can_create_promissory_note',
    text: 'Puede crear pagaré notarial',
    presets: [
      ADMIN_PRESET,
      DEV_PRESET,
      VALIDATION_PRESET,
      PRE_VALIDATION_PRESET,
    ],
    group: REPORT,
    order: 24,
  },
  CAN_READ_MOVEMENTS: {
    value: 'can_read_movements',
    text: 'Puede ver movimientos',
    presets: [DEV_PRESET],
    group: FINANCIAL_PANEL,
    order: 25,
  },
  CAN_READ_MOVEMENTS_SUMMARY: {
    value: 'can_read_movements_summary',
    text: 'Puede ver resumen de movimientos',
    presets: [DEV_PRESET],
    group: FINANCIAL_PANEL,
    order: 26,
  },
  CAN_READ_BANK_ACCOUNTS: {
    value: 'can_read_bank_accounts',
    text: 'Puede ver cuentas bancarias',
    presets: [DEV_PRESET],
    group: FINANCIAL_PANEL,
    order: 27,
  },
  CAN_UPDATE_BANK_ACCOUNT_BALANCE: {
    value: 'can_update_bank_account_balance',
    text: 'Puede actualizar balance de cuenta bancaria',
    presets: [DEV_PRESET],
    group: FINANCIAL_PANEL,
    order: 28,
  },
  CAN_READ_PRODUCTS: {
    value: 'CAN_READ_PRODUCTS',
    text: 'Puede ver productos iBAN Wallet',
    presets: [DEV_PRESET],
    group: INVESTMENT,
  },
  CAN_CREATE_PRODUCTS: {
    value: 'CAN_CREATE_PRODUCTS',
    text: 'Puede crear productos iBAN Wallet',
    presets: [DEV_PRESET],
    group: INVESTMENT,
  },
  CAN_READ_WALLET_USERS: {
    value: 'CAN_READ_WALLET_USERS',
    text: 'Puede ver usuarios iBAN Wallet',
    presets: [DEV_PRESET],
    group: INVESTMENT,
  },
  CAN_EDIT_WALLET_USERS: {
    value: 'CAN_EDIT_WALLET_USERS',
    text: 'Puede editar usuarios iBAN Wallet',
    presets: [DEV_PRESET],
    group: INVESTMENT,
  },
  CAN_READ_USER_PRODUCTS: {
    value: 'CAN_READ_USER_PRODUCTS',
    text: 'Puede leer productos de usuarios iBAN Wallet',
    presets: [DEV_PRESET],
    group: INVESTMENT,
  },
  CAN_CREATE_USER_PRODUCTS: {
    value: 'CAN_CREATE_USER_PRODUCTS',
    text: 'Puede crear productos a usuarios iBAN Wallet',
    presets: [DEV_PRESET],
    group: INVESTMENT,
  },
  CAN_UPDATE_LOAN_APPLICATION_REFERENCE_CODE: {
    value: 'CAN_UPDATE_LOAN_APPLICATION_REFERENCE_CODE',
    text: 'Puede editar código referencia en formulario',
    presets: [DEV_PRESET],
    group: ADMIN,
  },
  CAN_UPDATE_LOAN_APPLICATION_STATUS: {
    value: 'CAN_UPDATE_LOAN_APPLICATION_STATUS',
    text: 'Puede editar el estado de la solicitud',
    presets: [DEV_PRESET],
    group: ADMIN,
  },
  CAN_EDIT_TERMS_AND_CONDITIONS_OF_USE: {
    value: 'CAN_EDIT_TERMS_AND_CONDITIONS_OF_USE',
    text: 'Can update ibanwallet.com terms and conditions of use with GraphCMS',
    presets: [DEV_PRESET],
    group: ADMIN,
  },
};

const PermissionsKeys = Object.keys(Permissions);

export const PermissionsArray = PermissionsKeys.map(p => Permissions[p]);

export const PermissionsEnum = PermissionsKeys.reduce((acc, current) => {
  acc[current] = Permissions[current].value;
  return acc;
}, {});

// export const PermissionsOptions = PermissionsArray;

export const AdminPresetPermissions = PermissionsArray.filter(p =>
  p.presets.includes(ADMIN_PRESET)
);

export const CallCenterPresetPermissions = PermissionsArray.filter(p =>
  p.presets.includes(CALL_CENTER_PRESET)
);

export const PreValidationPresetPermissions = PermissionsArray.filter(p =>
  p.presets.includes(PRE_VALIDATION_PRESET)
);

export const ValidationPresetPermissions = PermissionsArray.filter(p =>
  p.presets.includes(VALIDATION_PRESET)
);

const {
  CAN_READ_HOME_LOAN_APPLICATIONS,
  CAN_READ_BUSINESS_LOAN_APPLICATIONS,
  CAN_READ_NOT_CLASSIFIED_APPLICATIONS,
} = PermissionsEnum;

/**
 * This permissions are used for navigation authorization and route based authorization.
 */
export const loanApplicationsPermissions = [
  CAN_READ_HOME_LOAN_APPLICATIONS,
  CAN_READ_BUSINESS_LOAN_APPLICATIONS,
  CAN_READ_NOT_CLASSIFIED_APPLICATIONS,
];

export default Permissions;
