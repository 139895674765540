import styled from 'styled-components';
import {
  maxWidth,
  minWidth,
  minHeight,
  space,
  flex,
  order,
  alignSelf,
  position,
} from 'styled-system';
import { fontSize, color, width } from 'config/style';

const Box = styled.div<StyledSystemProps>`
  box-sizing: border-box;
  ${fontSize};
  ${color};
  ${width};
  ${maxWidth};
  ${minWidth};
  ${minHeight};
  ${space};
  ${flex};
  ${order};
  ${alignSelf};
  ${position};
  ${props => props.css};
`;

Box.displayName = 'Box';

export default Box;
