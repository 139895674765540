import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';

import { querySearchParse } from '../../utils/helpers/general';

class PagingContainer extends React.Component {
  getCurrentPage = () => {
    const params = querySearchParse(this.props.location.search);
    const pageParam = params.page;
    return pageParam ? parseInt(pageParam, 10) : 1;
  };

  previousPage = () => {
    const page = this.getCurrentPage();
    const nextPage = page - 1 < 1 ? 1 : page - 1;
    if (page === nextPage) return;
    this.goToPage(nextPage);
  };

  nextPage = () => {
    const page = this.getCurrentPage();

    this.goToPage(page + 1);
  };

  goToPage = page => {
    const { history, path, location } = this.props;
    const params = {
      ...querySearchParse(location.search),
      page,
    };
    let search = Object.keys(params);
    search =
      search.length > 0 ? search.map(k => `${k}=${params[k]}`).join('&') : '';
    history.push(`${path}?${search}`);
    this.scrollTop();
  };

  scrollTop = () => {
    const { scrollToElement } = this.props;
    if (!scrollToElement) return;
    scroller.scrollTo(scrollToElement, {
      duration: 0,
      offset: -150,

      // delay: 100,
      // smooth: true
      // containerId: 'ContainerElementID',
    });
    // window.scrollTo(0, 0);
  };

  renderBottomPageContainer = () => {
    const { pageNumberPosition } = this.props;
    if (pageNumberPosition !== 'bottom') return false;
    const page = this.getCurrentPage();
    return <PageNumberContainer>{`Page ${page}`}</PageNumberContainer>;
  };

  renderTopPageContainer = () => {
    const { pageNumberPosition } = this.props;
    if (pageNumberPosition !== 'top') return false;
    const page = this.getCurrentPage();
    return <PageNumberContainer>{`Page ${page}`}</PageNumberContainer>;
  };

  render() {
    const { hasNextPage } = this.props;
    return (
      <div>
        {this.renderTopPageContainer()}
        <PagingContainerDiv>
          <button
            className="btn btn-link"
            type="button"
            onClick={this.previousPage}
          >
            Previous
          </button>
          <button
            className="btn btn-link"
            type="button"
            onClick={this.nextPage}
            disabled={!hasNextPage}
          >
            Next
          </button>
        </PagingContainerDiv>
        {this.renderBottomPageContainer()}
      </div>
    );
  }
}

const PagingContainerDiv = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`;
const PageNumberContainer = styled.div`
  text-align: center;
  margin: 10px;
`;

PagingContainer.propTypes = {
  path: PropTypes.string.isRequired,
  scrollToElement: PropTypes.string,
};

PagingContainer.defaultProps = {
  pageNumberPosition: 'top',
  hasNextPage: false,
};

export default withRouter(PagingContainer);
