/* eslint-disable consistent-return */
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { setCookie, deleteCookie } from 'services/api/auth/cookie';
import { updateTwoFactor } from 'services/api/auth/otp';
import * as settings from './settings';
import { REACT_APP_APP_URL } from '../../REACT_APP_APP_URL';
import requestHandler from 'services/api/requestHandler';

class Auth {
  accessToken;

  refreshToken;

  expiresAt;

  // Method exists for historical reasons.
  // Delete it when it's convenient to do so.
  handleAuthentication = () => {
    if (localStorage.getItem('isLoggedIn') === '1') {
      this.setSession();
    }
  };

  isAuthenticated = () => {
    const currentTime = new Date().getTime();
    var isAuthenticated = currentTime < localStorage.getItem('expiresAt');
    return isAuthenticated;
  };

  login = async (username, password, userType, errorCb) => {
    localStorage.setItem('username', username);
    localStorage.setItem('userType', userType);

    try {
      const result = await requestHandler.post('/api/v1/authentication/login', {
        username: username,
        passcode: password,
        userType: userType,
      });

      this.accessToken = result.data.access_token;
      this.refreshToken = result.data.refreshToken;
      this.updateExpiresAt(result.data.expires_in);

      localStorage.setItem('isLoggedIn', '1');
      localStorage.setItem('initialAccessToken', this.accessToken);
      localStorage.setItem('initialRefreshToken', this.refreshToken);
      localStorage.setItem('expiresAt', this.expiresAt);

      window.location.replace(REACT_APP_APP_URL);
    } catch {
      errorCb();
    }
  };

  logout = () => {
    this.accessToken = null;
    this.refreshToken = null;
    this.expiresAt = 0;

    deleteCookie();
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('hasValidatedOtp');
    localStorage.removeItem('initialAccessToken');
    localStorage.removeItem('initialRefreshToken');
    localStorage.removeItem('expiresAt');
  };

  getAccessToken = () => this.accessToken;

  updateTwoFactor = async (oldToken, newToken) => {
    await updateTwoFactor(oldToken, newToken);
  };

  setSession = async () => {
    if (localStorage && localStorage.getItem('hasValidatedOtp') === '1') {
      await setCookie(this.accessToken);
    }
  };

  updateExpiresAt = expiresIn => {
    this.expiresAt = Date.now() + expiresIn;
    localStorage.setItem('expiresAt', this.expiresAt);
  };

  renewSession = async () => {
    const result = await requestHandler.post(
      '/api/v1/authentication/refresh-token',
      {
        username: localStorage.getItem('username'),
        userType: localStorage.getItem('userType'),
        refreshToken: localStorage.getItem('initialRefreshToken'),
      }
    );

    if (
      result.data.access_token !== undefined &&
      result.data.access_token !== ''
    ) {
      var previousAccessToken = localStorage.getItem('initialAccessToken');

      this.accessToken = result.data.access_token;
      this.refreshToken = result.data.refreshToken;
      this.updateExpiresAt(result.data.expires_in);
      localStorage.setItem('initialRefreshToken', this.refreshToken);

      await this.updateTwoFactor(previousAccessToken, this.accessToken);

      await this.setSession();
    }
  };

  _getJWK = async () => {
    const res = await axios.get(
      `https://${settings.domain}/.well-known/jwks.json`
    );

    return res.data;
  };

  _verifyToken = async token => {
    if (token) {
      const decodedToken = jwt.decode(token, { complete: true });
      const jwk = await this._getJWK();
      let cert = jwk.keys[0].x5c[0];
      cert = cert.match(/.{1,64}/g).join('\n');
      cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;

      if (jwk.keys[0].kid === decodedToken.header.kid) {
        try {
          jwt.verify(token, cert);
          return true;
        } catch (error) {
          // console.error(error);
          return false;
        }
      }
    }

    return false;
  };
}

const Auth0Service = new Auth();

export default Auth0Service;
