import axios from 'axios';

import {
  filterRequestsUrl,
  requestHistoryUrl,
  countRequestsUrl,
  updateRequestNoteUrl,
  getImageUrl,
  uploadImageUrl,
  deleteImageUrl,
  updateRequestFieldUrl,
  archivedRequestsUrl,
  getRequestUrlByRequestId,
  expensesRequestUrl,
  RemoveExpensesRequestUrl,
  updateExpensesRequestUrl,
} from '../constants/endpointUrls';

import { SIGNED, DISBURSED } from '../constants/RequestStatus';

const {
  post, get, patch, put,
} = axios;
// const Promise = require('es6-promise').Promise;

const filterRequests = params => post(filterRequestsUrl, params);

const getExpenses = requestId => get(expensesRequestUrl(requestId));
const addExpenses = (requestId, params) => post(expensesRequestUrl(requestId), params);
const removeExpenses = (requestId, expensesId) => axios.delete(RemoveExpensesRequestUrl(requestId, expensesId));
const requestExpeses = (requestId, params) => put(expensesRequestUrl(requestId), params);
const updateExpense = ({ expenseList }) => patch(updateExpensesRequestUrl, {
  expense: expenseList,
});

const getRequestById = requestId => get(getRequestUrlByRequestId(requestId));
const updateRequest = (requestId, params) => {
  if (params.hasOwnProperty('formsituacion')) {
    switch (params.formsituacion) {
      case SIGNED:
        params.contract_sign_time = Date.now();
        break;
      case DISBURSED:
        params.disbursement_time = Date.now();
        break;
      default:
        break;
    }
  }

  return patch(getRequestUrlByRequestId(requestId), params);
};
const getRequestHistory = requestId => get(requestHistoryUrl(requestId));

const getRequestsCount = (partner) => {
  const params = new FormData();
  params.append('partner', partner);
  return post(countRequestsUrl, params);
};

const archiveRequest = (requestId) => {
  const params = new URLSearchParams();
  params.append('activo', 1);
  return patch(getRequestUrlByRequestId(requestId), params);
};
const updateRequestStatus = (requestId, value) => updateRequest(
  requestId, {
    formsituacion: value,
  },
);

const updateRequestNote = (requestId, noteText, isPrivate, user = null) => {
  const note = user ? `${noteText}` : noteText;
  const params = new URLSearchParams();
  params.append('value', note);
  params.append('private', isPrivate);

  return post(updateRequestNoteUrl(requestId), params, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
};

const getImage = id => get(getImageUrl(id));

const uploadImage = (image, nombre, fileType, loanId) => {
  const data = {
    file: image,
    userName: nombre,
    fileType,
  };

  return post(uploadImageUrl(loanId), data, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
};

const deleteImage = id => axios.delete(deleteImageUrl(id));

const updateRequestField = (requestId, fieldName, fieldValue, inputs) => {
  const params = new URLSearchParams();
  params.append('id', requestId);
  params.append(fieldName, fieldValue);

  params.append('casa', inputs.casa);
  params.append('tiene_negocio', inputs.tiene_negocio);
  params.append('tiene_empleo', inputs.tiene_empleo);
  params.append('vehiculo', inputs.vehiculo);

  return post(updateRequestFieldUrl, params);
};
const getArchivedRequests = params => post(archivedRequestsUrl, params);
export default {
  filterRequests,
  getRequestHistory,
  getRequestsCount,
  archiveRequest,
  updateRequestStatus,
  updateRequestNote,
  getImage,
  uploadImage,
  updateRequestField,
  getArchivedRequests,
  deleteImage,
  updateRequest,
  getRequestById,
  getExpenses,
  addExpenses,
  removeExpenses,
  requestExpeses,
  updateExpense,
};
