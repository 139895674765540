import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './i18n';

import configureStore from './configureStore';
import App from './App';
/* eslint-disable */
import { NumberExtension } from './utils/extensions/';
/* eslint-enable */

const store = configureStore();

const Component = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const render = () => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

render();
if (module.hot) {
  module.hot.accept(() => {
    render();
  });
}
