import { PermissionsEnum } from './Permissions';

const {
  CAN_READ_BUSINESS_LOAN_APPLICATIONS,
  CAN_READ_HOME_LOAN_APPLICATIONS,
  CAN_READ_NOT_CLASSIFIED_APPLICATIONS,
  CAN_READ_SOLUTION_LOAN_APPLICATIONS,
  CAN_READ_SOCIAL_LOAN_APPLICATIONS,
} = PermissionsEnum;

const LoanApplicationTypes = {
  SOLUTION: {
    value: 'solution',
    text: 'Solución',
    permission: CAN_READ_SOLUTION_LOAN_APPLICATIONS,
  },
  SOCIAL: {
    value: 'social',
    text: 'Social',
    permission: CAN_READ_SOCIAL_LOAN_APPLICATIONS,
  },
  BUSINESS: {
    value: 'negocio',
    text: 'Negocio',
    permission: CAN_READ_BUSINESS_LOAN_APPLICATIONS,
  },
  HOME: {
    value: 'home',
    text: 'Hogar',
    permission: CAN_READ_HOME_LOAN_APPLICATIONS,
  },
  NOT_CLASSIFIED: {
    value: 'no_clasificado',
    text: 'No clasificado',
    permission: CAN_READ_NOT_CLASSIFIED_APPLICATIONS,
  },
};
const LoanApplicationKeys = Object.keys(LoanApplicationTypes);

export const requestTypeOptions = LoanApplicationKeys.map(
  p => LoanApplicationTypes[p]
);

export const LoanApplicationTextFromValue = LoanApplicationKeys.reduce(
  (prev, current) => {
    const loanApplicationType = LoanApplicationTypes[current];
    return { ...prev, [loanApplicationType.value]: loanApplicationType.text };
  },
  {}
);

export default LoanApplicationTypes;
