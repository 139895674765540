import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { alertModal } from '../../actions/general';

class AlertModal extends React.Component {
  onBtnClick = type => {
    const { onConfirm, onCancel } = this.props;

    switch (type) {
      case 'confirm':
        if (onConfirm) onConfirm();
        break;
      case 'cancel':
        if (onCancel) onCancel();
        break;
      default:
        break;
    }

    this.props.alertModal(false);
  };

  closeModal = () => {
    this.props.alertModal(false);
  };

  render() {
    const { show, alertText } = this.props;
    const bodyText = alertText || 'Are you sure?';
    return (
      <Modal toggle={this.closeModal} isOpen={show} data-background="light">
        <ModalHeader>Alert</ModalHeader>
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => this.onBtnClick('confirm')}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-default btn-outline"
            onClick={() => this.onBtnClick('cancel')}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { show, onConfirm, onCancel, alertText } = state.ui;
  return {
    show,
    onConfirm,
    onCancel,
    alertText,
  };
};

export default connect(
  mapStateToProps,
  { alertModal }
)(AlertModal);
