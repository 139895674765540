import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// pages

import {
  AsyncUserDetailsPage,
  AsyncAdminDetailsPage,
  AsyncDistributorPlatformDetailsPage,
  AsyncWalletDetailsPage,
  AsyncBackendBankDetailsPage,
  AsyncReconciliationDetailsPage,
  AsyncTransactionsPage,
  AsyncTransactionsDistributorPlatformPage,
  AsyncOwnWalletsPage,
  AsyncPlatformWalletsPage,
  AsyncUserWalletsPage,
  AsyncTransactionDetailsPage,
  AsyncBackendBanksPage,
  AsyncReconciliationsPage,
  AsyncAdmins,
  AsyncUserScreening,
  AsyncUserScreeningDetail,
} from 'pages/Admin';
import {
  AsyncLoginAdmin,
  AsyncLoginDistributor,
  AsyncOtp,
  AsyncDashboard,
  AsyncDormant,
  AsyncTermsAndConditionsOfUse,
  AsyncLoggedId,
} from './pages';

// Investment
import {
  AsyncWalletUsers,
  AsyncDistributorAdmins,
  AsyncDistributorPlatforms,
} from './pages/Investment';

// tables

import ProtectedRoute from './components/HOC/ProtectedRoute';

import { REACT_APP_APP_URL, setReactAppAppUrl } from './REACT_APP_APP_URL';

class Routes extends Component {
  componentWillMount() {
    const urlRegexResult = /(http.+)\/#/.exec(window.location.href);
    if (urlRegexResult != null) {
      setReactAppAppUrl(urlRegexResult[1]);
    }
    // Note: Ideally we would now prevent REACT_APP_APP_URL from being modified.
  }

  render() {
    let loginRoute;
    if (REACT_APP_APP_URL === process.env.REACT_APP_ADMIN_URL) {
      loginRoute = <Route path="/login" component={AsyncLoginAdmin} />;
    } else if (REACT_APP_APP_URL === process.env.REACT_APP_DISTRIBUTOR_URL) {
      loginRoute = <Route path="/login" component={AsyncLoginDistributor} />;
    }

    return (
      <Switch>
        <ProtectedRoute
          path="/users/edit/:userId/:tab"
          component={AsyncUserDetailsPage}
        />
        <ProtectedRoute
          path="/admins/edit/:id/:tab"
          component={AsyncAdminDetailsPage}
        />
        <ProtectedRoute
          path="/distributorplatforms/edit/:id/:tab"
          component={AsyncDistributorPlatformDetailsPage}
        />
        <ProtectedRoute
          path="/user-wallets/edit/:walletId"
          component={AsyncWalletDetailsPage}
        />
        <ProtectedRoute
          path="/backend-banks/edit/:backendBankId"
          component={AsyncBackendBankDetailsPage}
        />
        <ProtectedRoute
          path="/reconciliations/:reconciliationType/edit/:reconciliationId"
          component={AsyncReconciliationDetailsPage}
        />
        <ProtectedRoute
          path="/transactions/:transactionId"
          component={AsyncTransactionDetailsPage}
        />
        <ProtectedRoute
          path="/transactions"
          component={AsyncTransactionsPage}
        />
        <ProtectedRoute
          path="/transactions-distributor-platform"
          component={AsyncTransactionsDistributorPlatformPage}
        />
        <ProtectedRoute path="/own-wallets" component={AsyncOwnWalletsPage} />
        <ProtectedRoute
          path="/platform-wallets"
          component={AsyncPlatformWalletsPage}
        />
        <ProtectedRoute path="/user-wallets" component={AsyncUserWalletsPage} />
        <ProtectedRoute path="/users" component={AsyncWalletUsers} />
        <ProtectedRoute
          path="/distributor-admins"
          component={AsyncDistributorAdmins}
        />
        <ProtectedRoute
          path="/distributor-platforms"
          component={AsyncDistributorPlatforms}
        />
        <ProtectedRoute path="/admins" component={AsyncAdmins} />
        <ProtectedRoute
          path="/backend-banks"
          component={AsyncBackendBanksPage}
        />
        <ProtectedRoute
          path="/reconciliations/:tab"
          component={AsyncReconciliationsPage}
        />
        <ProtectedRoute
          exact
          path="/user-screening/:tab"
          component={AsyncUserScreening}
        />
        <ProtectedRoute
          exact
          path="/user-screening/:id/details"
          component={AsyncUserScreeningDetail}
        />
        <Route path="/terms" component={AsyncTermsAndConditionsOfUse} />

        {loginRoute}

        <Route path="/otp" component={AsyncOtp} />
        <Route path="/user-registration" component={AsyncLoggedId} />
        <ProtectedRoute path="/dashboard" component={AsyncDashboard} />

        <Route path="/dormant" component={AsyncDormant} />

        <Route path="/" component={AsyncLoggedId} />
      </Switch>
    );
  }
}

export default Routes;
