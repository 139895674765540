import { rem, em } from 'polished';

const colors = {
  darkIndigo: '#0a0f35', // WARN: Iban Account color
  white: '#ffffff',
  offWhite: '#f6f3ed',
  veryLightGrey: '#c8c8c8',
  lightGrey: '#afafaf',
  paleGrey: '#f9f9f9',
  slateGrey: '#5e657b',
  grey: '#838383',
  darkGrey: '#4e4e4e',
  green: '#00ce7c',
  red: '#d0021b',
  greyBlue: '#8990c1',
  greyishBrown: '#4e4e4e',
  purpleBlue: '#5f50f6', // WARN: Iban Market color
  vibrantPurple: '#a400ce', // WARN: Iban One color
  coolGrey: '#9599a6',
  dustyOrange: '#f68d23', // WARN: Iban Investor color
  amber: '#d35549',
  blueyGrey: '#a8adbd',
  warning: '#e8b50f',
  paleOrange: '#ffb250',
  // new brand colors.
  primary: '#385586',
  secondary: '#5e657b',
  disabled: '#e6e6e6',
  textMuted: '#9599a6',
  textDark: '#000000',
  textBody: '#4e4e4e',
};

export const ibanAccountColors = {
  iban_account: 'darkIndigo',
  iban_one: 'vibrantPurple',
  iban_market: 'purpleBlue',
  iban_investor: 'dustyOrange',
};

const fontSizes = [
  10, // 0
  12, // 1
  14, // 2
  16, // 3
  18, // 4
  20, // 5
  22, // 6
  24, // 7
  32, // 8
  36, // 9
  42, // 10
  50, // 11
  64, // 12
].map(n => rem(n));

const breakpoints = [
  480, // small
  768, // medium
  1024, // large
  1200, // xlarge
].map(n => em(n));

const space = [0, 4, 8, 16, 32, 64, 128, 256].map(n => rem(n));

const fonts = {
  black: `'Circular Std Black', Helvetica, sans-serif`,
  bold: `'Circular Std Bold', Helvetica, sans-serif`,
  book: `'Circular Std Book', Helvetica, sans-serif`,
  medium: `'Circular Std Medium', Helvetica, sans-serif`,
};

const defaultGutter = [rem(10), null, rem(60)];

export const yAxisGutter = [rem(30), null, rem(60)];

export const topGutter = [null, null, null, rem(60)];

export const buttonStyles = {
  default: {
    color: 'white',
    bg: 'coolGrey',
  },
  primary: {
    color: 'white',
    bg: 'green',
  },
  secondary: {
    color: 'white',
    bg: 'darkIndigo',
  },
  danger: {
    color: 'white',
    bg: 'amber',
  },
};

const buttonSizes = {
  small: {
    fontSize: fontSizes[2],
    padding: rem(12),
  },
  normal: {
    fontSize: fontSizes[3],
    padding: rem(16),
  },
  medium: {
    fontSize: fontSizes[4],
    padding: rem(20),
  },
  large: {
    fontSize: fontSizes[7],
    padding: rem(20),
  },
};

export default {
  colors,
  fontSizes,
  breakpoints,
  space,
  fonts,
  defaultGutter,
  yAxisGutter,
  buttonSizes,
};
