import { ALERT_MODAL, SPINNER, SHOW_MODAL } from '../actions/types';

const initialState = {
  show: false,
  showSpinner: false,
  spinnerQueue: [],
  onConfirm: null,
  onCancel: null,
  alertText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT_MODAL: {
      const {
        onConfirm, onCancel, show, alertText,
      } = action;

      return {
        ...state,
        show,
        onConfirm,
        onCancel,
        alertText,
      };
    }
    case SHOW_MODAL: {
      const { modalName, show: showModal } = action;
      return {
        ...state,
        [modalName]: showModal,
      };
    }
    case SPINNER: {
      let { spinnerQueue } = state;
      if (action.show) spinnerQueue = spinnerQueue.concat('dummy');
      else spinnerQueue.shift();

      const showSpinner = spinnerQueue.length > 0;
      return {
        ...state,
        showSpinner,
        spinnerQueue: [...spinnerQueue],
      };
    }
    default: {
      return state;
    }
  }
};
