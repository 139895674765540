export const ALERT_MODAL = 'ALERT_MODAL';
export const SPINNER = 'SPINNER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const INIT_LOAN_INFO_MODAL = 'INIT_LOAN_INFO_MODAL';
export const INIT_NOTES_MODAL = 'INIT_NOTES_MODAL';
export const RESET_LOAN_MODAL = 'RESET_LOAN_MODAL';
export const RESET_NOTES_MODAL = 'RESET_NOTES_MODAL';
export const SET_LOAN = 'SET_LOAN';

export const UPDATE_FORM_REQUEST_INFO = 'UPDATE_FORM_REQUEST_INFO';
