import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
// import dropdown from '../json/user-dropdown.json';

import { logout } from '../actions/authActionCreators';

import '../css/elements/sidebar-heading-dropdown.css';

class SidebarHeadingDropdown extends Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { firstName, role } = this.props;
    const userName =
      firstName && firstName.length > 16
        ? `${firstName.slice(0, 15)}...`
        : firstName || '';
    return (
      <div className="sidebar-heading-dropdown">
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="btn btn-default btn-flat dropdown-toggle">
            <div className="name">{userName}</div>
            <div className="location">
              {role === 'advisor' && <span>Distributor</span>}
              {role === 'operations' && <span>Admin</span>}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {/* {dropdown.map((item, i) => (
              <DropdownItem key={i}>
                <i className={item.icon} />
                <span className="title">{item.name}</span>
                <div className="separator" />
                {item.badge && (
                  <span className={item.badgeClass}>{item.badgeText}</span>
                )}
              </DropdownItem>
            ))} */}
            <DropdownItem onClick={this.props.logout}>
              <i className="sli-power" />
              <span className="title">Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { firstName, role } = state.user;
  return {
    firstName,
    role,
  };
};
export default connect(
  mapStateToProps,
  { logout }
)(SidebarHeadingDropdown);
