import { format } from 'date-fns';

const getDateValuesFromTimestamp = timestamp => {
  const date = new Date(timestamp);
  return [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
};

export const timestampToDateString = val => {
  if (!val || Number.isNaN(val)) return '';
  const [year, month, day] = getDateValuesFromTimestamp(val);
  return `${day}/${month}/${year}`;
};

export const timestampToISODateString = (val, includeTime = false) => {
  const tokenFormat = includeTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
  return format(val, tokenFormat);
};
export const timestampToISODateObject = val => ({
  date: format(val, 'YYYY-MM-DD'),
  time: format(val, 'HH:mm:ss'),
});

// This object will be filled with currency formatters
// by getCurrencyFormatter as required
const currencies = {};
const getCurrencyFormatter = (currency = 'EUR') => {
  let formatter = currencies[currency];

  if (!formatter) {
    formatter = new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency,
    });
    currencies[currency] = formatter;
  }

  return formatter;
};

export function formatCurrency(val, options = {}) {
  return getCurrencyFormatter(options.currencyAlpha3Code).format(val);
}

export function convertToPercent(val) {
  return parseFloat(val) / 100;
}

const percentFormatter = new Intl.NumberFormat(navigator.language, {
  style: 'percent',
  maximumFractionDigits: 2,
}).format;

export const formatRate = val => percentFormatter(convertToPercent(val));
