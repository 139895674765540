const {
  REACT_APP_IBAN_HOST_API: hostApi,
  REACT_APP_IBAN_API: ibanApi,
  REACT_APP_IBAN_AUTH_API: ibanAuthApi,
  REACT_APP_INSURANCE_LOANS_URL,
} = process.env;

const authAppName = 'do-erp';
const clientAreaAuthAppName = 'do-client-area';

const setUrl = path => `${hostApi}/${path}`;
const setApiUrl = path => `${ibanApi}/${path}`;
const setAuthApiUrl = path => `${ibanAuthApi}/${authAppName}/${path}`;
const setClientAreaAuthApiUrl = path =>
  `${ibanAuthApi}/${clientAreaAuthAppName}/${path}`;

// Api urls
export const loanAppUrl = setApiUrl('loan-applications');
export const loansUrl = setApiUrl('loans');
export const transactionsUrl = (rest = '') => setApiUrl(`transactions${rest}`);

// Requests url
export const requestHistoryUrl = loanId =>
  setApiUrl(`loan-applications/${loanId}/get-notes`);
export const filterRequestsUrl = setUrl('filter.php');
export const countRequestsUrl = setUrl('count.php');
export const updateRequestStatusUrl = setUrl('situacion.php');
export const updateRequestNoteUrl = loanId =>
  setApiUrl(`loan-applications/${loanId}/insert-note`);
export const getImageUrl = loanId =>
  setApiUrl(`loan-applications/${loanId}/get-document`);
export const deleteImageUrl = loanId => setApiUrl(`documentation/${loanId}`);
export const uploadImageUrl = loanId =>
  setApiUrl(`loan-applications/${loanId}/upload-document-with-drive`);
export const updateRequestFieldUrl = setUrl('updateSolicitud.php');
export const archivedRequestsUrl = setUrl('filterArchivado.php');
export const expensesRequestUrl = loanId => setApiUrl(`expenses/${loanId}`);
export const updateExpensesRequestUrl = setApiUrl('expenses');
export const RemoveExpensesRequestUrl = (loanId, expensesId) =>
  setApiUrl(`expenses/${expensesId}`);
export const getRequestUrlByRequestId = requestId =>
  `${loanAppUrl}/${requestId}`;

// Loans
export const loanProjectionUrl = `${loansUrl}/projection`;
export const makeLoanPaymentUrlByLoandId = loanId =>
  `${loansUrl}/${loanId}/payments`;
export const createLoanContractUrl = `${loansUrl}/create-loan-contract`;
export const createPromissoryNoteUrl = `${loansUrl}/create-promissory-note`;
export const applyDiscountUrl = (loanId, dueId) =>
  `${loansUrl}/${loanId}/dues/${dueId}/apply-discount`;
export const insuranceLoanUrl = REACT_APP_INSURANCE_LOANS_URL;
export const updateLoanUrl = loanId => `${loansUrl}/${loanId}`;

// Auth/Profile url
export const signUpUrl = setUrl('userv2.php');
export const signUpIbanUrl = `${ibanAuthApi}/users`;
export const registrationTokenUrl = `${ibanAuthApi}/users/registration-token`;
export const createPofileUrl = userId =>
  setAuthApiUrl(`users/${userId}/profile`);
export const signInUrl = setAuthApiUrl('token');
export const retreiveProfileByTokenUrl = setAuthApiUrl('profiles/me');
export const retreiveProfileByUserIdUrl = userId =>
  setAuthApiUrl(`profiles/${userId}`);
export const retreiveClientAreaProfileByUserIdUrl = userId =>
  setClientAreaAuthApiUrl(`profiles/${userId}`);
export const getProfilesUrl = setAuthApiUrl('profiles');
export const getClientAreaProfilesUrl = setClientAreaAuthApiUrl('profiles');

// Permissions Url
export const getPermissionsUrl = setAuthApiUrl('permissions');
export const createPermissionsUrl = setAuthApiUrl('permissions');
export const grantPermissionsToUserUrl = userId =>
  setAuthApiUrl(`users/${userId}/grant-permissions`);
export const revokePermissionsToUserUrl = userId =>
  setAuthApiUrl(`users/${userId}/revoke-permissions`);

// Transactions
export const transactionsSummaryUrl = transactionsUrl('/summary');
export const transactionsSummaryOfficesUrl = transactionsUrl(
  '/summary/offices'
);

// Bank accounts
export const bankAccountsUrl = (rest = '') => setApiUrl(`bank-accounts${rest}`);
export const getBankAccountByIdUrl = bankAccountId =>
  bankAccountsUrl(`/${bankAccountId}`);
export const bankAccountBalancesUrl = bankAccountId =>
  bankAccountsUrl(`/${bankAccountId}/balances`);

// Brokers
export const brokersUrl = (rest = '') => setApiUrl(`brokers${rest}`);

// Mandates
export const mandatesUrl = (rest = '') => setApiUrl(`mandates${rest}`);
