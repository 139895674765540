/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setConfig } from '../actions/config';
import LogoContainer from './Logo';
// import DropdownUser from './DropdownUser';

import '../css/elements/navbar-1.css';
// import LanguageSelect from '../components/General/LanguageSelect';

class Navbar1 extends Component {
  constructor() {
    super();
    this.toggleLayout = this.toggleLayout.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  toggleLayout() {
    this.props.setConfig('collapsed', !this.props.config.collapsed);
  }

  toggleRightSidebar() {
    this.props.setConfig('rightSidebar', !this.props.config.rightSidebar);
  }

  render() {
    return (
      <nav className="navbar navbar-1 d-flex justify-content-between align-items-center flex-nowrap">
        <LogoContainer />

        <ul className="nav nav-inline hidden-sm-down">
          <li className="nav-item">
            <a onClick={this.toggleLayout} className="nav-link toggle-layout">
              <i className="sli-menu" />
            </a>
          </li>
        </ul>
        <div className="separator" />
        <ul className="nav nav-inline">
          <li className="nav-item hidden-md-up">
            <a onClick={this.toggleLayout} className="nav-link toggle-layout">
              <i className="sli-menu" />
            </a>
          </li>
        </ul>

        {/* <ul className="nav nav-inline hidden-md-down"> */}
          {/* <DropdownUser /> */}
          {/* <DropdownFlags /> */}
          {/* <LanguageSelect /> */}
        {/* </ul> */}
      </nav>
    );
  }
}
const mapStateToProps = state => ({
  config: state.config,
});
const mapDispatchToProps = dispatch => ({
  setConfig: (key, value) => dispatch(setConfig(key, value)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar1);
