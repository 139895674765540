import { toast } from 'react-toastify';
import UserService from '../utils/services/UserService';
import { SET_LOGGED_USER_PROFILE, GET_LOGGED_USER_PROFILE } from './userTypes';
import { storeKeys } from '../utils/helpers/storageManager';

import { viewSpinner, hideSpinner } from './general';
import { SET_PROFILE, CLEAR_PROFILE } from './profileTypes';

// import { viewSpinner, hideSpinner } from './general';

export const getProfileStart = () => ({
  type: GET_LOGGED_USER_PROFILE,
});

export const setAuthenticatedProfile = userProfile => {
  const propsToStore = {
    ...userProfile,
  };
  delete propsToStore.id;
  delete propsToStore.identityProviderId;
  storeKeys(propsToStore);
  // storeKeys({
  //   permissions: JSON.stringify(permissions),
  // });

  return {
    type: SET_LOGGED_USER_PROFILE,
    payload: {
      ...propsToStore,
    },
  };
};

export const setRole = role => {
  storeKeys({ role });

  return {
    type: 'SET_ROLE',
    payload: {
      role,
    },
  };
};

// export const getProfileFromToken = token => dispatch => {
// dispatch(getProfileStart());
// UserService.getProfileByToken(token)(
//   res => {
//     if (res.data && res.data.data) {
//       const { userId, email, profile } = res.data.data;
//       const {
//         name,
//         partner,
//         permissions,
//         profileId,
//         referenceCode
//       } = profile;
//       dispatch(
//         setAuthenticatedProfile(
//           userId,
//           email,
//           name,
//           permissions,
//           profileId,
//           partner,
//           referenceCode
//         )
//       );
//       dispatch(authSuccess(token));
//     }
//   },
//   () => {
//     dispatch(hideSpinner());
//   },
//   () => {
//     (errorGeneralNotification());
//   }
// );
// };
const setProfile = user => {
  const { email, userId, profile } = user;
  const { name, partner, permissions, referenceCode } = profile;
  return {
    type: SET_PROFILE,
    email,
    userId,
    name,
    partner,
    permissions,
    referenceCode,
  };
};
export const clearProfile = () => ({
  type: CLEAR_PROFILE,
});
export const getProfileFromUserId = userId => dispatch => {
  dispatch(viewSpinner());
  UserService.getProfileByUserId(userId)(
    res => {
      const user = res.data.data;
      dispatch(setProfile(user));
    },
    () => {
      dispatch(hideSpinner());
    },
    () => {
      toast.error('Something went wrong');
    }
  );
};
export const getClientAreaProfileFromUserId = (
  userId,
  onSuccess = () => {},
  onComplete = () => {}
) => () => {
  // dispatch(viewSpinner());
  UserService.getClientAreaProfileByUserId(userId)(
    res => {
      onSuccess(res.data.data);
    },
    () => {
      // dispatch(hideSpinner());
      onComplete();
    },
    () => {
      // (errorGeneralNotification());
    }
  );
};
