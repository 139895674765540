import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import theme from 'config/theme';
import Toast from 'components/Toast';
import history from './history';

import 'react-datepicker/dist/react-datepicker.css';

// global css
import './css/bootstrap/scss/bootstrap.css';
import './css/main.css';
import './css/ui-elements/typography.css';
import './css/ui-elements/cards.css';
import './css/ui-elements/buttons.css';
import './css/icons/simple-line-icons.css';

import Spinner from './components/UI/Spinner';

import Routes from './Routes';

class App extends Component {
  componentWillMount() {
    // const { pathname } = this.props;
    // this.setLayout(pathname);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <div>
            <Spinner />
            <Toast />

            <Routes />
          </div>
        </ConnectedRouter>
      </ThemeProvider>
    );
  }
}

export default App;
