/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { setConfig } from '../actions/config';

import {
  isAnyOfFirstInSecond,
  // isAllOfFirstInSecond,
} from '../utils/helpers/general';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    let isOpen = false;
    const { pathname } = props.location;
    const { items } = props;
    if (items.items.filter(item => pathname.includes(item.pathname)).length > 0)
      isOpen = true;
    this.state = {
      isOpen,
    };
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  collapseMenu = () => {
    // TODO: 768 it's the same as the media query. At this breakpoint left side bar assumes it's mobile.
    if (window.innerWidth <= 768)
      this.props.setConfig('collapsed', !this.props.config.collapsed);
  };

  render() {
    // eslint-disable-next-line prefer-destructuring
    const items = this.props.items;
    const { role, userPermissions } = this.props;
    const { pathname } = this.props.location;

    if (items.items.length === 0) {
      if (items.permissions.length > 0) {
        if (!isAnyOfFirstInSecond(items.permissions, [role])) return false;
      }
      if (items.userPermissions.length > 0) {
        if (!isAnyOfFirstInSecond(items.userPermissions, userPermissions))
          return false;
      }
      const activeClass = ''; // items.pathname === pathname ? 'focus' : '';

      return (
        <li>
          <Link
            to={items.url}
            className={`btn btn-default btn-flat btn-sidebar btn-sidebar-1 ${activeClass}`}
            onClick={this.collapseMenu}
          >
            <i className={items.icon} />
            <span className="title">{items.title}</span>
            {items.badge && (
              <span className={`ml-auto ${items.badge.className}`}>
                {items.badge.title}
              </span>
            )}
          </Link>
        </li>
      );
    }
    const subItems = items.items.filter(
      item => {
        if (item.permissions.length > 0)
          if (!isAnyOfFirstInSecond(item.permissions, [role])) return false;

        return true;
      }
      // if (item.mandatoryPermissions.length > 0) {
      //   if (!isAllOfFirstInSecond(item.mandatoryPermissions, userPermissions)) return false;
      // }

      // if (item.permissions.length > 0) {
      //   if (!isAnyOfFirstInSecond(item.permissions, userPermissions)) return false;
      // }
    );
    if (subItems.length === 0) return false;
    return (
      <li>
        <a
          className={
            this.state.isOpen
              ? 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children is-open'
              : 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children'
          }
          onClick={this.toggle}
        >
          <i className={items.icon} />
          <span className="title">{items.title}</span>
          {items.badge && (
            <span className={`ml-auto ${items.badge.className}`}>
              {items.badge.title}
            </span>
          )}
        </a>
        <Collapse isOpen={this.state.isOpen}>
          <ul className="list-unstyled">
            {subItems.map((item, k) => {
              const activeClass = pathname.includes(item.pathname)
                ? 'focus'
                : '';
              return (
                <li key={k}>
                  <Link
                    to={item.url}
                    className={`btn btn-default btn-flat btn-sidebar btn-sidebar-2 ${activeClass}`}
                    onClick={this.collapseMenu}
                  >
                    <i className={item.icon} />
                    <span className="title">{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Collapse>
      </li>
    );
  }
}
const mapStateToProps = state => {
  const { permissions, role } = state.user;
  return {
    userPermissions: permissions,
    config: state.config,
    role,
  };
};
export default withRouter(connect(mapStateToProps, { setConfig })(Menu));
