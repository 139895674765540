import React from 'react';
import { toast, ToastContainer, Slide } from 'react-toastify';
import styled from 'styled-components';
import css from './css';

const Style = styled.div`
  ${css}
`;

const Toast = () => (
  <Style>
    <ToastContainer
      position={toast.POSITION.TOP_RIGHT}
      transition={Slide}
      autoClose={5000}
    />
  </Style>
);

export default Toast;
